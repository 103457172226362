export default {
  'assessmentLobby.title': 'Waiting Room',
  'assessmentLobby.proctoringInfo':
    'Exam proctoring rules are active. You must follow these rules in the waiting room as well.',
  'assessmentLobby.startInfo':
    'When the exam start time comes, the "{start}" button above will be active.',
  'assessmentLobby.start': 'Start Exam',
  'assessmentLobby.examInfo': 'Exam Information',
  'assessmentLobby.hours': 'Hours',
  'assessmentLobby.minutes': 'Minutes',
  'assessmentLobby.seconds': 'Seconds',
  'assessmentLobby.days': 'Days',
};

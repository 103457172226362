export default {
  'assessmentDetails.title': 'Assessment Details',
  'assessmentDetails.navigation':
    'Allow navigation between questions in the exam',
  'assessmentDetails.elimination': 'Option elimination (Only for MCQ)',
  'assessmentDetails.5min': "Show 'you have five (5) minutes left' warning",
  'assessmentDetails.shuffleOptions':
    'Mix the options of the questions (Only for MCQ)',
  'assessmentDetails.shuffleQuestions': 'Mix all questions',

  'assessmentDetails.unit': 'Unit / Course',
  'assessmentDetails.assesmentCategory': 'Assessment category',
  'assessmentDetails.assessmentMethod': 'Assessment method',
  'assessmentDetails.resultType': 'Announcement of results',
  'assessmentDetails.INSTANT_PRELIMINARY_RESULT':
    'Show result after the candidate finishes the exam.',
  'assessmentDetails.MANUALLY_BY_OWNER': 'The creator manually',
  'assessmentDetails.PRELIMINARY_RESULT': 'Show result after the exam ends.',
  'assessmentDetails.timeOver': 'When time is over',
  'assessmentDetails.validAnswers': 'Send given answers and complete the exam',
  'assessmentResults.invalidAnswers':
    'Consider given answers invalid and complete the exam',
  'assessmentDetails.assessmentDetails': 'Assessment details',
  'assessmentDetails.assessmentCategory': 'Assessment category',
  'assessmentDetails.highestGrade': 'The highest score',
  'assessmentDetails.correctionGuessing': 'Random guessing fix',
  'assessmentDetails.options': 'Details',
  'assessmentDetails.active': 'Active',
  'assessmentDetails.passive': 'Passive',
  'assessmentDetails.NONE': 'None',
  'assessmentDetails.update': 'Update',
  'assessmentDetails.process': 'Exam execution options',
  'assessmentDetails.gradingMethod': 'Passing criteria',
  'assessmentDetails.proctoringType': 'Proctoring type',
  'assessmentDetails.LOCKDOWN_REVIEW': 'Lockdown & Review',
  'assessmentDetails.RECORD_REVIEW': 'Record & Review',
  'assessmentDetails.UNPROCTORED': 'Unproctored',
  'assessmentDetails.assessmentDescription': 'Assessment Description',
  'assessmentDetails.understand': 'I have read & understood',
  'assessmentDetails.criteria1Info':
    'It is a type of evaluation that defines the success of the candidate according to a specified passing score.',
  'assessmentDetails.purifyInfo':
    'To discourage random guessing, each incorrect answer given by candidates in multiple-choice questions is scored negatively based on the number of choices for that question. For example, if a question with five options and worth 10 points is answered incorrectly by making a guess; The scores of the question are divided into 5-1=4 slices, and one slice, namely -2.5 negative points is applied.',
  'assessmentDetails.RECORD_REVIEW-tooltip': `In this type of proctoring, video and screen recording of the candidate is taken during the exam. These recordings are processed with artificial intelligence algorithms after the exam, and suspicious behavior, people and objects are presented to the examiner in the form of a marked video. In this way, the examiner can complete the entire exam security assessment in a very short time by watching only the marked sections of certain candidates, instead of watching the video recordings of many candidates that can take hours. This proctoring type currently covers all the features of the Lockdown & Review surveillance type. Recommended for exams of high importance.`,
  'assessmentDetails.LOCKDOWN_REVIEW-tooltip': `In this type of proctoring, the device that the candidate connects to the exam is subject to various restrictions such as full-screen forcing and not being able to print. Some types of violations that cannot be restricted, such as turning off screen sharing, are also reported for this type of proctoring. This proctoring type produces a risk score for the candidates after the exam, allowing the examiner to evaluate the violations committed by the designated candidates on the window and device. All features of this type are also available for the Record & Review proctoring type. Recommended for exams of low to medium importance.`,
  'assessmentDetails.UNPROCTORED-tooltip': `It is a type of proctoring in which no supervision or restriction is made during the exam. A report is not produced after the exam. It can be used in low-importance exams or quizzes.`,
};

export default {
  'prepareBrowserStep.prepareBrowser': 'Prepare Browser',
  'prepareBrowserStep.desc':
    'According to the rules of the exam you will take, you need to make some preliminary preparations.',
  'prepareBrowserStep.extension': 'Examod Proctoring Extension',
  'prepareBrowserStep.checked': 'Being checked...',
  'prepareBrowserStep.browserCheckError': 'Not found',
  'prepareBrowserStep.browserCheckSuccess': 'Approved',
  'prepareBrowserStep.dowloadLink': 'Install',
  'prepareBrowserStep.addToChrome': 'Add to Chrome',
  'prepareBrowserStep.examRequirementChromeAlert':
    'This exam requires the <b>Google Chrome</b> web browser.',
  'prepareBrowserStep.examRequirementProctoringAlert':
    'This exam requires the <b>Google Chrome</b> web browser and <b>Examod Proctoring</b> extension.',
  'prepareBrowserStep.extensionRequired':
    'This exam requires the <b>Examod Proctoring Extension</b> application.',
  'prepareBrowserStep.extensionTooltip':
    'The Examod Proctoring extension provides various controls on your screen share and web browser during the exam. The extension prevents cheating by prioritizing candidate privacy and data security. For this exam, the Examod Proctoring extension must be active during the exam.',
  'prepareBrowserStep.extensionNotFoundTitle':
    '<b>Examod Proctoring</b> extension not found. Please follow the instructions below and <b>refresh</b> the page.',
  'prepareBrowserStep.extensionNotFoundInfo1':
    'Make sure the <b>Examod Proctoring</b> extension is installed and updated. <b><u>(Add to Chrome)</u></b>',
  'prepareBrowserStep.extensionNotFoundInfo2':
    'Make sure the <b>Examod Proctoring</b> extension is active.',
  'prepareBrowserStep.extensionNotFoundInfo3':
    'Try uninstall and re-install the extension.',
  'prepareBrowserStep.extensionNotFoundInfo4':
    'And <b><u>REFRESH</u></b> the page.',
};

export default {
  'time.hour': '{hour} hr',
  'time.min': '{min} min',
  'time.sec': '{sec} sec',
  'time.hours': 'hours',
  'time.minutes': 'minutes',
  'time.seconds': 'seconds',
  'time.days': 'days',
  'time.timezone': 'System Time Zone: {timezone}',
};

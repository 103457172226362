export default {
  'userDetails.verified': 'Doğrulanmış',
  'userDetails.not_verified': 'Doğrulanmamış',
  'userDetails.profileButton': 'Profil',
  'userDetails.language': 'Tercih Edilen Dil',
  'userDetails.systemId': 'Sistem ID',
  'userDetails.email': 'Email',
  'userDetails.status': 'Durum',
  'userStatus.active': 'Aktif',
  'userStatus.passivated': 'Pasif',
  'userStatus.pending_invitation': 'Bekleyen Davet',
};

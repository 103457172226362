export default {
  'assessmentDetails.title': 'Değerlendirme Detayları',
  'assessmentDetails.navigation':
    'Sınavdaki sorular arasında gezinmeye izin ver',
  'assessmentDetails.elimination': 'Seçenek eleme (Sadece ÇSS)',
  'assessmentDetails.5min': 'Son beş (5) dakikanız kaldı uyarısını göster',
  'assessmentDetails.shuffleOptions':
    'Soruların seçeneklerini karıştır (Sadece ÇSS)',
  'assessmentDetails.shuffleQuestions': 'Tüm soruları karıştır',

  'assessmentDetails.unit': 'Birim / Ders',
  'assessmentDetails.assesmentCategory': 'Değerlendirme kategorisi',
  'assessmentDetails.assessmentMethod': 'Değerlendirme yöntemi',
  'assessmentDetails.resultType': 'Sonuçların ilanı',
  'assessmentDetails.INSTANT_PRELIMINARY_RESULT':
    'Değerlendirme sonucunu adayın uygulaması tamamlandığında göster.',
  'assessmentDetails.MANUALLY_BY_OWNER':
    'Değerlendirme yaratıcısı tarafından elle',
  'assessmentDetails.PRELIMINARY_RESULT':
    'Değerlendirme sonucunu uygulama tarihi bitiminde göster.',
  'assessmentDetails.timeOver': 'Süre dolduğunda',
  'assessmentDetails.validAnswers':
    'Önceki yanıtlar gönderilsin ve sınav tamamlansın',
  'assessmentResults.invalidAnswers':
    'Verilen cevaplar geçersiz sayılsın ve sınav tamamlansın',
  'assessmentDetails.assessmentDetails': 'Değerlendirme detayları',
  'assessmentDetails.assessmentCategory': 'Değerlendirme kategorisi',
  'assessmentDetails.highestGrade': 'En yüksek puan',
  'assessmentDetails.correctionGuessing': 'Rastgele tahmin düzeltmesi',
  'assessmentDetails.options': 'Detaylar',
  'assessmentDetails.active': 'Aktif',
  'assessmentDetails.passive': 'Pasif',
  'assessmentDetails.NONE': 'Yok',
  'assessmentDetails.update': 'Güncelle',
  'assessmentDetails.process': 'Sınav yürütme seçenekleri',
  'assessmentDetails.gradingMethod': 'Geçme ölçütü',
  'assessmentDetails.proctoringType': 'Gözetim türü',
  'assessmentDetails.LOCKDOWN_REVIEW': 'Kilitle ve İncele',
  'assessmentDetails.RECORD_REVIEW': 'Kaydet ve İncele',
  'assessmentDetails.UNPROCTORED': 'Gözetimsiz',
  'assessmentDetails.assessmentDescription': 'Değerlendirme açıklaması',
  'assessmentDetails.understand': 'Okudum ve anladım',
  'assessmentDetails.criteria1Info':
    'Belirlenmiş bir geçme puanına göre adayın başarılı olma durumunu tanımlayan değerlendirme türüdür.',
  'assessmentDetails.purifyInfo':
    'Rastgele tahmin etmeyi caydırmak için, adayların çoktan seçmeli sorularda verdikleri her bir yanlış yanıt için o sorunun seçenek sayısına göre negatif puanlama yapılır. Örneğin, beş seçenekli ve 10 puan değerindeki bir soruya tahmin yapılarak yanlış yanıt verildiğinde; sorunun puanları 5-1=4 dilime ayrılır ve bir dilim yani -2.5 negatif puan uygulanır.',
  'assessmentDetails.RECORD_REVIEW-tooltip': `Bu gözetim türünde, sınav sırasında adayın video ve ekran kaydı alınır. Bu kayıtlar sınav sonrasında yapay zeka algoritmalarıyla işlenerek, şüpheli davranış, kişi ve nesneler sınav gözetmenine işaretlenmiş video gösterimi şeklinde sunulur. Bu sayede sınav gözetmeni birçok adayın saatlerce sürebilecek video kayıtlarını izlemek yerine belirli adayların sadece işaretli kesitlerini izleyerek çok az zamanda tüm sınav güvenlik değerlendirmesini tamamlayabilir. Bu gözetim türü halihazırda Kilitle ve İncele gözetim türünün tüm özelliklerini kapsamaktadır. Yüksek önem düzeyine sahip sınavlar için önerilir.`,
  'assessmentDetails.LOCKDOWN_REVIEW-tooltip': `Bu gözetim türünde adayın sınava bağlandığı cihaz, tam ekran zorlama, çıktı alamama gibi çeşitli kısıtlamalara tabi tutulur. Ekran paylaşımının kapatılması gibi kısıtlama yapılamayan bazı ihlal türleri de bu gözetim türü için raporlanmaktadır. Bu gözetim türü, sınav sonrasında adaylarla ilgili bir risk puanı üreterek sınav sorumlusuna belirlenmiş adayların penrece ve cihaz üzerinde yaptığı ihlalleri değerlendirme olanağı tanır. Bu türün tüm özellikleri Kaydet ve İncele gözetim türü için de kullanılabilir. Düşük ve orta önem düzeyine sahip sınavlar için önerilir.`,
  'assessmentDetails.UNPROCTORED-tooltip': `Sınav sırasında herhangi bir gözetim ve kısıtlamanın yapılmadığı gözetim türüdür. Sınav sonrasında bir rapor üretilmez.Düşük önem düzeyine sahip sınavlar veya quiz sınavlarında kullanılabilir.`,
};

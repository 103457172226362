export default {
  'home.userStatistics': 'Kullanıcı İstatistikleri',
  'home.activeUsers': 'Kuruluştaki Aktif Kullanıcılar',
  'home.pendingUsers': 'Davet Bekleyen Kullanıcılar',
  'home.userGroupsCreated': 'Kullanıcı Grubu Oluşturuldu',
  'home.rolesDefined': 'Sistemde Tanımlanan Roller',
  'home.assessmentStatistics': 'Değerlendirme İstatistikleri',
  'home.examsPublished': 'Sınav Yayınlandı',
  'home.ongoingExams': 'Devam Eden Sınavlar',
  'home.questionCreated': 'Soru Oluşturuldu',
  'home.updatedNow': 'Yeni Güncellendi',
  'home.actions': 'İşlemler',
  'home.edit': 'Kullanıcıyı Düzenle/Görüntüle',
  'home.usersInvited': 'Davet Edilmiş Kullanıcılar',
  'home.welcome': 'Hoş Geldin,',
  'home.upcomingExams': 'Yakındaki ve Mevcut Sınavlar',
  'home.upcomingExamDetails': 'Detaylar',
  'home.checkSystem': 'Online sınavlar için sisteminizi kontrol edin',
  'home.checkSystemButton': 'Kontrol et',
  'home.lastExamResults': 'Son Sınav Sonuçlarınız',
  'home.examResultExamName': 'Sınav adı',
  'home.assessmentType': 'Tür',
  'home.examResultCourseName': 'Birim / Ders adı',
  'home.examResult': 'Sınav sonucu',
  'home.examResultActions': 'İşlemler',
  'home.examResultActionButton': 'Sınav Raporu',
  'home.supportRequests': 'Destek Talepleri',
  'home.supportRequestSubject': 'Konu',
  'home.supportRequestStatus': 'Durum',
  'home.supportRequestSolved': 'Çözüldü',
  'home.supportRequestNotSolved': 'Çözülmedi',
  'home.supportRequestButtonRequests': 'Talepler',
  'home.supportRequestButtonCreate': 'Talep oluştur',
  'home.systemUpdates': 'Sürüm Notları ve Sistem Güncellemeleri',
  'home.noUpcomingExams': 'Planlanmış bir sınav bulunmamaktadır.',
  'home.ticketText':
    'Examod ile ilgili yaşadığınız sorunlara ilişkin teknik destek talebi oluşurabilirsiniz. Size en kısa sürede dönüş yapmaya çalışacağız.',
  'home.examResultTooltip': 'Aday Puanı / Maksimum Puan',
  'home.update1': 'Değerlendirme oluşturma sorunu düzeltildi.',
  'home.update2': "Genel Bakış'taki takvim boyutu ve renkleri düzeltildi.",
  'home.update3':
    'Değerlendirme ismi girilirken girdi alanı kontrolü düzeltildi.',
  'home.update4':
    'Genel Bakış ekranı güncellendi. Etkileşimli takvimle birlikte yaklaşan sınavlar, kullanıcı istatistikleri ve yetkiye bağlı olarak Examod istatistikleri ve destek talebi oluşturma eklendi.',
  'home.update5':
    'Birim silinebilme durumu güncellendi. Bir birimin alt birimlerinde sınav yapılabilirlik ve/veya soru bankası özelliği olan birim var ise o birim silinemez.',
  'home.update6':
    'Soru eklerken varsayılan olarak ilgili birimin soru özelliklerinin seçili olmaması durumu düzeltildi.',
  'home.update7': 'Rubrik listesinde arama yapmak için arama alanı eklendi.',
};

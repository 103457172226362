import { httpClient } from '../utility/networkUtil';
import { onApiError } from '../utility/apiUtil';
import axios from 'axios';
import { BASE_URL } from '../configs/apiConfig';

const PROJECT_PREFIX = '/testing';
const API_PREFIX = `${PROJECT_PREFIX}/session`;

/** Checks if there is an active session exists */
export const checkActiveSessionExists = async () => {
  return httpClient
    .get(`${API_PREFIX}/active`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};
/** Gets session info*/
export const getSession = async (sessionId) => {
  return httpClient
    .get(`${API_PREFIX}/${sessionId}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};
/** Creates a new session for given assessment */
export const createSession = async (assessmentId, browser, operatingSystem) => {
  return httpClient
    .post(`${API_PREFIX}`, { assessmentId, browser, operatingSystem })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};
/** sets session to ONGOING directly (TEMPORARY) */
export const skipSessionSteps = async (sessionId) => {
  return httpClient
    .put(`${API_PREFIX}/${sessionId}/shortcut`, { sessionId })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};
/** Calls for next step */
export const goToNextStep = async (sessionId) => {
  return httpClient
    .put(`${API_PREFIX}/${sessionId}/next`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/** Calls for previous step */
export const goToPreviousStep = async (sessionId) => {
  return httpClient
    .put(`${API_PREFIX}/${sessionId}/previous`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/** Saves user's answer for given question */
export const saveAnswer = async (sessionId, data) => {
  return httpClient
    .post(`${API_PREFIX}/${sessionId}/answer`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};
/** Ends assessment session for user */
export const finishSession = async (sessionId) => {
  return httpClient
    .post(`${API_PREFIX}/${sessionId}/finish`, null)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/** Ping service that keeps the assessment session alive */
export const sendPing = async (sessionId) => {
  return httpClient
    .put(`${API_PREFIX}/${sessionId}/ping`, { sessionId })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

// /** check if user is verified
export const checkUserIsVerified = async (sessionId, data) => {
  return httpClient
    .put(`${API_PREFIX}/${sessionId}/verifyId`, {
      vectors: data,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

// ** Reset session steps
export const restSessionSteps = async (sessionId) => {
  return httpClient
    .put(`${API_PREFIX}/${sessionId}/pause`, { sessionId })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

export const eventRecorder = async (
  sessionId,
  eventType,
  eventActionType,
  requiresExtension
) => {
  let customCommon = httpClient.defaults.headers.common;
  if (!requiresExtension) {
    //removing client type if no need extension
    let { 'X-Client-Type': _, ...common } = httpClient.defaults.headers.common;
    customCommon = common;
  }
  const customHttpClient = axios.create({
    baseURL: BASE_URL,
    headers: { ...httpClient.defaults.headers, common: customCommon },
  });
  return customHttpClient
    .post(API_PREFIX + `/${sessionId}/event`, {
      eventType: eventType,
      eventActionType: eventActionType,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

// ** Upload proof video for candidate before enter exam
export const uploadVideo = async (sessionId, formData) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return httpClient
    .post(API_PREFIX + `/${sessionId}/upload/video`, formData, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

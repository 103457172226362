export const ERROR_CODES = {
  INVALID_TOKEN: 381,
  EXPIRED_TOKEN: 382,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER: 500,
  ASSESSMENT_SESSION_FINALIZED: 2002,
  EXTENSION_NOT_ALIVE: 2032,
  SESSION_RESET: 2034,
};

export const FORM_ERROR_TYPES = {
  required: 'required',
  pattern: 'pattern',
  maxLength: 'maxLength',
  minLength: 'minLength',
  validate: 'validate',
};

import React, { createContext, useReducer } from 'react';
import { getFlaggedQuestions } from '../data/UserLocalDataSource';

let session = {
  user: null,
  flaggedQuestions: getFlaggedQuestions(),
  sessionInfo: null,
};

export const MainContext = createContext(session);

export const sessionReducer = (state, newState) => {
  return { ...state, ...newState };
};

function Store({ initialSession, children }) {
  session = {
    ...session,
    ...initialSession,
  };
  const [state, dispatch] = useReducer(sessionReducer, session);
  const value = { state, dispatch };

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
}

Store.defaultProps = { children: null };

export default Store;

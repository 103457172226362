import { history } from './history';
import { PATHS } from './navigation/Paths';
import { CONTROLLERS } from './navigation/ControllerPrefix';

export const navigateToDashboard = () => {
  history.push(PATHS.dashboard);
};

export const navigateToMyAssessments = () => {
  history.push(PATHS.myAssessments);
};

export const navigateToInternalError = () => {
  history.push(PATHS.errorInternal);
};

export const navigateToUnknownError = () => {
  history.push(PATHS.unknownError);
};

export const navigateToFeBasedError = () => {
  history.push(PATHS.feBasedError);
};

export const navigateToAboutUs = () => {
  history.push(PATHS.about);
};

export const navigateToLogin = () => {
  history.push(PATHS.login);
};

export const navigateToSetPassword = (currentPassword) => {
  history.push(PATHS.setPassword, {
    currentPassword: currentPassword,
  });
};

export const navigateToInvite = () => {
  history.push(PATHS.invitePreview);
};

export const navigateToNotAuthorised = () => {
  history.push(PATHS.errorNotAuthorised);
};

export const navigateToResourceNotFound = () => {
  history.push(PATHS.errorResourceNotFound);
};

export const navigateToAssessment = (
  assessmentId,
  sessionId,
  sessionStepInfo,
  assessmentViewInfo
) => {
  history.push(`${CONTROLLERS.assesssments}/${assessmentId}/${sessionId}`, {
    sessionStepInfo: sessionStepInfo,
    assessmentViewInfo: assessmentViewInfo,
  });
};

export const navigateToAssessmentInit = (
  assessmentId,
  sessionId,
  assessmentViewInfo
) => {
  history.push(
    `${CONTROLLERS.assesssments}/${assessmentId}/${sessionId}/init`,
    {
      assessmentViewInfo: assessmentViewInfo,
    }
  );
};

export const navigateToExamReport = (assessmentId) => {
  history.push(`${CONTROLLERS.my_assessments}/${assessmentId}/report`);
};

export const navigateToExamEntrance = (assessmentId) => {
  history.push(`${CONTROLLERS.my_assessments}/${assessmentId}/entrance`);
};

export const navigateToExamInformation = (assessmentId, isPerformance) => {
  history.push(`${CONTROLLERS.my_assessments}/${assessmentId}/information`, {
    isPerformance: isPerformance,
  });
};

export const navigateToNewTicket = () => {
  history.push(PATHS.newTicket);
};

export const navigateToChangePassword = () => {
  history.push(`${CONTROLLERS.profile}/change-password`);
};

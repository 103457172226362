export default {
  'assessmentSetup.BROWSER_SETUP': 'Tarayıcıyı Hazırlama',
  'assessmentSetup.GET_READY': 'Sınava Hazırlık',
  'assessmentSetup.AGREEMENT_APPROVAL': 'Onay Aşamaları',
  'assessmentSetup.PROCTORING_APPROVAL': 'Onay Aşamaları',
  'assessmentSetup.SYSTEM_CHECK': 'Sistem Kontrolü',
  'assessmentSetup.ID_CHECK': 'Aday Doğrulama',
  'assessmentSetup.AUDIO_RECORD': 'Adayın Ses Kaydının Alınması',
  'assessmentSetup.FINAL_PROMPT': 'Bekleme Odası',
  'assessmentSetup.startExam': 'Sınava Başla',
  'assessmentSetup.privacyInfoTitle': 'Gizlilik ve Güvenlik',
  'assessmentSetup.privacyInfo':
    'Gizliliğiniz ve kişisel verilerinizin güvenliği birinci önceliğimiz.',
  'assessmentSetup.stepInfo':
    'Emeğinizin güvenliğini sağlamamız için bize yardımcı olun ve adımları izleyin.',
  'assessmentSetup.questions': 'Soru sayısı',
  'assessmentSetup.time': 'Sınav süresi',
  'assessmentSetup.noDuration': 'Süre yok',
  'assessmentSetup.startDate': 'Başlangıç tarihi',
  'assessmentSetup.endDate': 'Bitiş tarihi',
  'assessmentSetup.ready': 'Başlamaya Hazırım',
  'assessmentSetup.question': '{count} soru',
  'assessmentSetup.nextStep': 'Sonraki Adım',
  'assessmentSetup.prevStep': 'Önceki Adım',
  'assessmentSetup.detailsDesc': 'Detaylar ve Açıklama',
};

export default {
  "photoCheck.title": "Taking the Candidate's Photo",
  "photoCheck.desc":
    "Share your face by following the directions below and taking three photos of your face from the front, left and right.",
  "photoCheck.approved": "Approved",
  "photoCheck.notStarted": "Not Started",
  "photoCheck.frontView": "Front View",
  "photoCheck.leftView": "Left View",
  "photoCheck.rightView": "Right View",
  "photoCheck.takePhoto": "Take a photo",
  "photoCheck.repeat": "Repeat",
  "photoCheck.identity": "Identity Card",
  "photoCheck.space": "You can use the SPACE button to take a picture.",

  "photoCheck.step1_1": "Look straight into the camera with your ears visible.",
  "photoCheck.step1_2": "Your eyes should be open.",
  "photoCheck.step1_3": "Faces should be visible down to the chin.",

  "photoCheck.step2_1":
    "Turn your head to the left so that your entire ear is visible.",
  "photoCheck.step2_2": "Your chin should be fully visible.",

  "photoCheck.step3_1":
    "Turn your head to the right so that your entire ear is visible.",
  "photoCheck.step3_2": "Your chin should be fully visible.",

  "photoCheck.step4_1":
    "Place an ID card provided by official institutions on the screen.",
  "photoCheck.step4_2": "The ID card must contain a recent photograph of you.",
};

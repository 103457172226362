import {
  saveToken,
  removeToken,
  getToken,
  getLang,
  saveLang,
  saveDeviceInfo,
  getLocalDeviceInfo,
  removeRememberToken,
  getRememberToken,
  removeSessionInfo,
} from './UserLocalDataSource';
import { isApiCallSuccess, onApiError } from '../utility/apiUtil';
import {
  httpClient,
  setAuthToken,
  dropAuthToken,
  setLanguage,
  setDeviceId,
} from '../utility/networkUtil';
import { isUserCandidate } from '../domain/User';
import { ERROR_CODES } from '../constants/Error';
import { SYSTEM_LANGUAGES } from '../utility/context/Internationalization';

const API_PREFIX = '/user';

/*
 * Logins to System
 */
export const login = async (email, password, organization, userType) => {
  const config = {
    headers: {
      'X-Org': organization,
      'X-User-Type': userType,
    },
  };

  return httpClient
    .post(
      API_PREFIX + '/login',
      {
        email: email,
        password: password,
      },
      config
    )
    .then((response) => {
      if (isApiCallSuccess(response)) {
        saveToken(response.data.data.token);
        setAuthToken(response.data.data.token);
      }
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Logouts From System
 */
export const logout = () => {
  removeToken();
  dropAuthToken();
  removeRememberToken();
  removeSessionInfo();
  return true;
};

/*
 * Returnes if user is logged in
 */
export const isLoggedIn = () => {
  return getToken() != null;
};

export const isRememberedLoggedIn = () => {
  return getRememberToken() != null;
};
export const isDeviceIdStored = () => {
  return (
    getLocalDeviceInfo()?.deviceId != null ||
    getLocalDeviceInfo()?.deviceId != undefined
  );
};

/*
 * Get Profile of Current User
 */
export const getProfile = async () => {
  return httpClient
    .get(API_PREFIX + '/info')
    .then((response) => {
      if (isUserCandidate(response.data.data)) {
        return response.data;
      } else {
        logout();
      }
    })
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Updates Profile Details
 */
export const updateProfile = async (
  userName,
  firstName,
  lastName,
  preferredLanguage,
  timezone
) => {
  return httpClient
    .put(API_PREFIX + '/profile', {
      username: userName,
      name: firstName,
      surname: lastName,
      dateOfBirth: '01/01/1900',
      preferredLanguage: preferredLanguage,
      zoneId: timezone,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Changes Password
 */
export const changePassword = async (oldPassword, newPassword) => {
  return httpClient
    .post(API_PREFIX + '/password/update', {
      currentPassword: oldPassword,
      newPassword: newPassword,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Gets Invitation Details
 */
export const getInvitationPreview = async (token) => {
  return httpClient
    .get(API_PREFIX + '/invitation/preview', {
      params: {
        token: token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Accepts the invite
 */
export const acceptInvitation = async (token, password, name, lastName) => {
  return httpClient
    .post(API_PREFIX + '/invitation/accept', {
      token: token,
      password: password,
      name: name,
      lastname: lastName,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Inits user language
 */
export const initUserLanguage = () => {
  let lang = getLang();
  if (lang == undefined) {
    lang = SYSTEM_LANGUAGES.EN;
  }
  saveLang(lang);
  setLanguage(lang);
};

/*
 * Updates user language
 */
export const updateUserLanguage = (currentLang) => {
  saveLang(currentLang);
  setLanguage(currentLang);
};

/*
 * Initiates forgot password to reset password
 */
export const forgotPasswordInit = async (email, organizationId, errorToast) => {
  let config = {};
  if (organizationId) {
    config = {
      headers: {
        'X-Org': organizationId,
      },
    };
  }
  return httpClient
    .post(
      `${API_PREFIX}/password/forgot/init`,
      {
        email,
      },
      config
    )
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === ERROR_CODES.UNAUTHORIZED) {
        errorToast();
      } else {
        onApiError(err);
      }
    });
};

/*
 * Finalize Forget Password
 */
export const forgotPasswordFinalize = async (token, newPassword) =>
  httpClient
    .post(`${API_PREFIX}/password/forgot/finalize`, {
      token,
      newPassword,
    })
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Forgot password guidance where forgot password flow start
 */
export const forgotPasswordGuidance = async (email) =>
  httpClient
    .post(`${API_PREFIX}/password/forgot/guidance`, {
      email,
    })
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Get User Groups
 */
export const getUserGroups = async (page, pageSize) => {
  return httpClient
    .get(API_PREFIX + '/group/search', {
      params: {
        page: page,
        pageSize: pageSize,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Get User Group By Id
 */
export const getUserGroupById = async (groupId) => {
  return httpClient
    .get(API_PREFIX + '/group/' + groupId)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Add User Group
 */
export const addUserGroup = async (groupName, groupDescription) => {
  return httpClient
    .post(API_PREFIX + '/group', {
      groupName: groupName,
      groupDescription: groupDescription,
      userIds: [],
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Update User Group By Id
 */
export const updateUserGroupById = async (
  groupId,
  groupName,
  groupDescription
) => {
  return httpClient
    .put(API_PREFIX + '/group/' + groupId, {
      groupName: groupName,
      groupDescription: groupDescription,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Delete User Group By Id
 */
export const deleteUserGroupById = async (groupId) => {
  return httpClient
    .delete(API_PREFIX + '/group/' + groupId)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Remove Users In Group By Id
 */
export const removeUsersInGroupById = async (groupId) => {
  return httpClient
    .delete(API_PREFIX + '/group/' + groupId + '/remove')
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Update Users In Group By Id
 */
export const updateUsersInGroupById = async (groupId, userIds) => {
  return httpClient
    .put(API_PREFIX + '/group/' + groupId + '/replace', {
      userIds: userIds,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

export const getRegisterDevice = async () => {
  return httpClient
    .post(API_PREFIX + '/device')
    .then((response) => {
      if (isApiCallSuccess(response)) {
        saveDeviceInfo(response.data.data);
        setDeviceId(response.data.data.deviceId);
      }
      return response.data;
    })
    .catch((err) => {
      onApiError(err);
    });
};

import React from 'react';
import { toast } from "react-toastify"

import successToastImg from '../../src/assets/img/toasts/successToast.svg';
import errorToastImg from '../../src/assets/img/toasts/errorToast.svg';

import ToastMessage from '../components/examod/toast/ToastMessage';


export const toastError = (message) => {
    toast.error(<ToastMessage message= {message} imgSrc={errorToastImg} titleId={'toasts.error'}/>, {
        autoClose: 3000,
        position: toast.POSITION.BOTTOM_CENTER
      })
}

export const toastSuccess = (message) => {
    toast.success(<ToastMessage message= {message} imgSrc={successToastImg} titleId={'toasts.success'}/>, {
        autoClose: 3000,
        position: toast.POSITION.BOTTOM_CENTER
      })
}

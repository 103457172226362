import { getLang } from '../data/UserLocalDataSource';
import { supportedLanguages } from '../utility/context/Internationalization';

export const getLocalizedNumber = (data) => {
  const lang = getLang();
  return lang === supportedLanguages[1].locale && data
    ? data.toString().replace('.', ',')
    : data
    ? data
    : 0;
};

// ** Returns a localized double
export const getLocalizedDouble = (data) => {
  const lang = getLang();
  return lang === supportedLanguages[1].locale && data
    ? data.toFixed(2).toString().replace('.', ',')
    : data.toFixed(2) || 0;
};

export const registerDevice = 'registerDevice';

export const onOpenOrgSite = (website) => {
  window.open(website, '_blank');
};

export const navigateToExternalPage = (url) => {
  if (!url) return;
  window.open(url, '_blank');
};

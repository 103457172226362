export default {
  'systemCheck.camera': 'Kameraya erişim',
  'systemCheck.mic': 'Mikrofona erişim',
  'systemCheck.shareScreen': 'Ekran Paylaşımı',
  'systemCheck.internetSpeed': 'İnternet Hızı (>1 mbps)',
  'systemCheck.light': 'Uygun Ortam Işığı',
  'systemCheck.access': 'Bilgisayar cihazı erişimi',
  'systemCheck.requirements': 'Sistem Gereksinimleri',
  'systemCheck.virtual': 'Sanal makine kontrolü',
  'systemCheck.devTools': 'Geliştirici Araçları',
  'systemCheck.singleScreen': 'Sadece tek ekran',
  'systemCheck.singleTab': 'Sadece tek sekme',
  'systemCheck.title': 'Sistem Kontrolleri',
  'systemCheck.helpText': 'Bir sorun var. Yardım ister misin?',
  'systemCheck.desc':
    'Bu aşamada sisteminizi kontrol edeceğiz ve problemleri çözmenizde yardımcı olacağız.',
  'systemCheck.checked': 'Kontrol ediliyor...',
  'systemCheck.systemCheckSuccess': 'İzin verildi',
  'systemCheck.systemCheckError': 'İzin verilmedi',
  'systemCheck.refresh': 'Yenile',
  'systemCheck.appropriate': 'Uygun',
  'systemCheck.notAppropriate': 'Uygun değil',
  'systemCheck.recheck': 'Tekrar kontrol et',
  'systemCheck.initNavigationResetError':
    'Sınav oturumunuz yetkili tarafından sıfırlanmıştır. Sınava devam etmek için "Değerlendirmelerim" menüsünden sınava giriş yapın.',
  'systemCheck.extensionError':
    'Web tarayıcınızda Examod Proctoring eklentisi bulunamadı. Lütfen ekranda yer alan hata mesajındaki yönergeleri takip edin. Yönergeleri uyguladıktan sonra sayfayı yenileyin.',

  // tooltips
  'systemCheck.mic-tooltip':
    'Sınav kuralları gereği, tarayıcınızın mikrofona erişmesi gerekmektedir. ',
  'systemCheck.camera-tooltip':
    'Sınav kuralları gereği, tarayıcınızın kameraya erişmesi gerekmektedir. ',
  'systemCheck.internetSpeed-tooltip':
    'Sınav kuralları gereği, sınav süresince ağ trafiğinizin takibi için internet hızınızın belirlenen referansın üzerinde olması gerekmektedir.',
  'systemCheck.shareScreen-tooltip':
    'Sınav kuralları gereği, tarayıcınızın ekran paylaşımı yapması gerekmektedir. ',
  'systemCheck.singleScreen-tooltip':
    'Sınav kuralları gereği, sınav süresince cihazınızda yalnızca bir ekran kullanmanız gerekmektedir.',
  'systemCheck.singleTab-tooltip':
    'Sınav kuralları gereği, sınav süresince cihazınızda yalnızca bir sekme kullanmanız gerekmektedir.',
  'systemCheck.access-tooltip':
    'Sınav kuralları gereği, sınava yalnızca bilgisayar ile giriş yapmanız gerekmektedir.',
  'systemCheck.virtual-tooltip':
    'Sınav kuralları gereği, sanal makine ile sınava katılamazsınız.',
};

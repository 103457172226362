import { CONTROLLERS } from './ControllerPrefix';

export const PATHS = {
  dashboard: '/dashboard',
  myAssessments: '/my-assessments',
  assessmentReport: CONTROLLERS.my_assessments + '/:assessmentId/report',
  exam: CONTROLLERS.my_assessments + '/:assessmentId',
  examEntrance: CONTROLLERS.my_assessments + '/:assessmentId/entrance',
  examInformation: CONTROLLERS.my_assessments + '/:assessmentId/information',
  assessment: CONTROLLERS.assesssments + '/:assessmentId/:sessionId',
  assessmentInit: CONTROLLERS.assesssments + '/:assessmentId/:sessionId/init',
  errorInternal: '/500',
  unknownError: '/unknown-error',
  feBasedError: '/error',
  errorNotAuthorised: '/not-authorized',
  errorResourceNotFound: '/404',
  profile: CONTROLLERS.profile + '/:tab?',
  about: CONTROLLERS.pages + '/about',
  login: '/login',
  setPassword: '/set-password',
  invitePreview: CONTROLLERS.invite + '/preview',
  newTicket: CONTROLLERS.help + '/ticket',
  faq: CONTROLLERS.help + '/faq',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  responsePreview: `${CONTROLLERS.my_assessments}/:assessmentId/response-preview`,
};

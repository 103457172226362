export default {
    "menu.dashboard": "Yönetim Paneli",
    "menu.dashboards": "Yönetim Panelleri",
    "menu.home": "Ana Sayfa",
    "menu.accountDetails": "Hesap Detayları",
    "menu.settings": "Ayarlar",
    "menu.globalSettings": "Global Ayarlar",
    "menu.generalSettings": "Genel Ayarlar",
    "menu.structuralSettings": "Yapısal Ayarlar",
    "menu.general": "Genel",
    "menu.structural": "Yapısal",
    "menu.units": "Birimler",
    "menu.periods": "Dönemler",
    "menu.exams": "Sınavlar",
    "menu.examEntrance": "Sınav Girişi",
    "menu.examEntrance_examName": "{examName} Girişi",
    "menu.examInformation": "Sınav Bilgileri",
    "menu.examInformation_examName": "{examName} Bilgileri",
    "menu.examReport": "Aday Sonuç Raporu",
    "menu.examReport_candidateName": "{candidateName} Sonuç Raporu",
    "menu.questionFields": "Soru Alanları",
    "menu.accounts": "Hesaplar",
    "menu.users": "Kullanıcılar",
    "menu.groups": "Gruplar",
    "menu.roles": "Roller",
    "menu.assessment": "Değerlendirme",
    "menu.myUnits": "Birimlerim",
    "menu.myCourses": "Derslerim",
    "menu.help": "Yardım",
    "menu.newTicket": "Yeni Kayıt",
    "menu.raiseSupport": "Kullanıcı Destek",
    "menu.faq": "Sıkça Sorulan Sorular",
    "menu.about": "Hakkımızda",
    "menu.myAssessments": "Değerlendirmelerim",
    "menu.welcome": "Hoşgeldiniz",
    "menu.overview": "Genel Bakış",
    "menu.profile": "Profil",
};

export default {
  'report.assessmentOverview': 'Değerlendirmeye Genel Bakış',
  'report.score': 'Aday Puanı',
  'report.candidateOverview': 'Adaya Genel Bakış',
  'report.unitCourse': 'Birim / Ders',
  'report.examiner': 'Sınav sorumlusu',
  'report.type': 'Değerlendirme türü',
  'report.category': 'Değerlendirme kategorisi',
  'report.highestScore': 'En yüksek puan',
  'report.highestScore-tooltip':
    'Bu değerlendirmeden alınabilecek en yüksek puan değeri.',
  'report.randomGuessing': 'Rastgele tahmin düzeltmesi',
  'report.randomGuessing-tooltip':
    'Rastgele tahmin etmeyi caydırmak için, adayların çoktan seçmeli sorularda verdikleri her bir yanlış yanıt için o sorunun seçenek sayısına göre negatif puanlama yapılır. Örneğin, beş seçenekli ve 10 puan değerindeki bir soruya tahmin yapılarak yanlış yanıt verildiğinde; sorunun puanları 5-1=4 dilime ayrılır ve bir dilim yani -2.5 negatif puan uygulanır.',
  'report.passingCriteria': 'Geçme ölçütü',
  'report.passingCriteria-tooltip':
    'Belirlenmiş bir geçme puanına göre adayın başarılı olma durumunu tanımlayan değerlendirme türüdür.',
  'report.passingScore': 'Geçme puanı',
  'report.rank': 'Sıralama',
  'report.quartile': 'Çeyrek sıralama',
  'report.duration': 'Süre',
  'report.examStart': 'Sınav başlangıç zamanı',
  'report.examEnd': 'Sınav bitiş zamanı',
  'report.questionCount': 'Sorular',
  'report.correctAnswers': 'Doğru yanıtlar',
  'report.incorrectAnswers': 'Yanlış yanıtlar',
  'report.unansweredQuestions': 'Yanıtlanmayan sorular',
  'report.unviewedQuestions': 'Görüntülenememiş sorular',
  'report.netScore': 'Net puan',
  'report.rawAndNet': 'Ham Puan / Net Puan',
  'report.netScore-tooltip': 'Adayın değerlendirme puanı',
  'report.rawAndNet-tooltip':
    'Ham puan, değerlendirmeden elde edilen işlenmemiş puanı ve net puan, rastgele tahmin düzeltmesi uygulanan puanı temsil eder.',
  'report.averageScore': 'Genel net puan ortalaması',
  'report.topicTitle': 'Konular',
  'report.objTitle': 'Öğrenme Hedefleri',
  'report.topicName': 'konu adı',
  'report.objName': 'öğrenme hedefi adı',
  'report.totalQuestion': ' doğru yanıt / toplam soru',
  'report.totalQuestionQa':
    "İlişkilendirilmiş SÖ'ye göre Doğru Yanıt / Soru Sayısı",
  'report.weight': 'ağırlık (%)',
  'report.weightQa': "İlişkilendirilmiş SÖ'ye Göre Ağırlık (%)",
  'report.scoreRange': 'Puan Aralığı (%)',
  'report.avgScore': 'Ortalama Puan',
  'report.yourScore': 'Aday Puanı',
  'report.radarTopicTitle': 'Konulara Göre Radar Grafiği',
  'report.radarObjTitle': 'Öğrenme Hedeflerine Göre Radar Grafiği',
  'report.resultReport': '{nameSurname} Sonuç Raporu',
  'report.candidate': 'Aday',
  'report.topicSubtitle': 'TÜM KONULAR',
  'report.objSubtitle': 'TÜM ÖĞRENME HEDEFLERİ',
  'report.yes': 'Evet',
  'report.per': '%{per}',
  'report.assessmentMethod': 'Değerlendirme yöntemi',
  'report.rubricName': 'Dereceli puanlama anahtarı',
  'report.criteria': 'Ölçütler',
  'report.allCriteria': 'Tüm Ölçütler',
  'report.level': 'Aday Düzeyi / Genel Düzey',
  'report.examinerFeedbacks': 'Değerlendiricinin Geribildirimleri',
  'report.feedback-tooltip':
    'Sınav görevlisinin değerlendirmeye yönelik yazılı ve sözlü geri bildirimleri.',
  'report.criteriaTitle': 'Ölçütlere Göre Aday Performansı',
  'report.criteriaSubtitle': 'Ölçütlere göre aday performansını gözden geçirin',
  'report.RUBRIC': 'Dereceli puanlama anahtarı',
  'report.assessmentName': 'Değerlendirme adı',
  'report.candidateName': 'İsim soyisim',
  'report.assessmentStatus': 'Değerlendirme durumu',
  'report.status': 'Durum',
  'report.resultMainTitle': 'Aday Sonuç Raporu',
  'report.resultMainSubtitle': 'Adayın sonuç raporunu inceleyin',
  'report.performanceTitle':
    'Konulara ve Öğrenme Hedeflerine Göre Aday Performansı',
  'report.performanceSubtitle':
    'Konulara ve öğrenme hedeflerine göre aday performansını gözden geçirin',
  'report.description': 'AÇIKLAMA',
  'report.result': 'SONUÇ',
  'report.candidateOverviewTitle': 'ADAYA GENEL BAKIŞ',
  'report.assessmentOverviewTitle': 'DEĞERLENDİRME DETAYLARI',
  'report.turkish': 'Türkçe',
  'report.english': 'İngilizce',
  'report.NOT_STARTED': 'Başlamadı',
  'report.ONGOING': 'Devam ediyor',
  'report.COMPLETED': 'Tamamlandı',
  'report.CANCELLED': 'İptal edildi',
  'report.INVALID': 'Geçersiz',
  'report.NOT_IMPORTED': 'Aktarılmadı',
  'report.review': 'İncele',
  'report.performanceTitleByTopics': 'Konulara Göre Aday Performansı',
  'report.performanceTitleByLO': 'Öğrenme Hedeflerine Göre Aday Performansı',
  'report.performanceSubtitleByTopics':
    'Konulara göre adayın sınav performansını gözden geçirin',
  'report.performanceSubtitleByLO':
    'Öğrenme hedeflerine göre adayın sınav performansını gözden geçirin',
  'report.performanceTitleByAttribute':
    'Soru Özelliklerine Göre Aday Performansı ({attributeName})',
  'report.performanceSubtitleByAttribute':
    'Bu soru özelliğinin, sorularla ilişkilendirilmiş seçeneklerine göre adayın sınav performansını inceleyin.',
  'report.attributeName': 'Özellik Seçeneği Adı',
  'report.attributeSubtitle': 'Tüm soru özelliği seçenekleri',
  'report.attributeTitle': 'Soru Özelliği Seçeneklerine Göre İstatistikle',
  'report.radarAttributeTitle': 'Soru Özelliği Seçeneklerine Göre Radar Grafik',

  'report.performanceTitleByCourse': 'Derslere Göre Aday Performansı',
  'report.performanceSubtitleByCourse':
    'Derslere göre aday performansını gözden geçirin',
  'report.courseName': 'Ders Adı',
  'report.courseSubtitle': 'Tüm Dersler',
  'report.courseTitle': 'Dersler',
  'report.radarCourseTitle': 'Derslere Göre Radar Grafiği',
  'report.radarCourseTitleAlert':
    "Ders sayısı 3'ün altında olduğu için istatistiksel hesaplamalar yanıltıcı olabilir.",
  'report.numberOfCourse':
    "Ders sayısı 15'in üstünde olduğu için radar grafiği oluşturulması uygun değildir.",
  'report.reportTotalQuestionTooltip':
    'Bu sütunda yer alan veriler, sadece sorularla ilişkilendirilen soru özelliği baz alınarak hesaplanmıştır. Sınavda yer alan bir soru bu soru özelliği ile ilişkilendirilmemişse bu alanda hesaba katılmamıştır.',
  'report.reportWeightTooltip':
    'Bu sütunda yer alan veriler, sadece sorularla ilişkilendirilen soru özelliği baz alınarak hesaplanmıştır. Sınavda yer alan bir soru bu soru özelliği ile ilişkilendirilmemişse bu alanda hesaba katılmamıştır.',
};

export default {
  'entrance.title': 'Exam Information',
  'entrance.description': 'Assessment Description',
  'entrance.overdueToast':
    'Only ongoing and incomplete exams can be entered. If you think there is a problem, contact the system authorized.',
  'entrance.details': 'Assessment Details',
  'entrance.proctoringDetails': 'Proctoring rules',
  'entrance.rubricDetails': 'Rubric Details ({name})',
  'entrance.chromeRequired': 'Examod Proctoring Extension Required - {dowload}',
  'entrance.examodProctoringExtension': 'Examod Proctoring Extension',
  'entrance.dowload': 'Download',
  'entrance.enterExam': 'Enter Exam',
  'entrance.emptyDesc': 'There is no description available.',
  'entrance.leftTime':
    'You can access the exam control steps at the earliest <b>15 minutes</b> before the exam start time.',
  'entrance.extensionAndBrowser':
    'This exam requires the <b>Google Chrome</b> web browser and <b>Examod Proctoring</b> extension.',
  'entrance.browserControl':
    'This exam requires Google Chrome web browser. Use the Google Chrome web browser to enter the exam.',

  // Assessment Details
  'entrance.tilteDetails': 'Details',
  'entrance.titleDescription': 'Description',
  'entrance.type': 'Assessment type',
  'entrance.category': 'Category',
  'entrance.examStart': 'Start date',
  'entrance.examEnd': 'End date',
  'entrance.duration': 'Duration',
  'entrance.highestScore': 'The highest score',
  'entrance.passingCriteria': 'Passing criteria',
  'entrance.randomGuessing': 'Random guessing fix',
  'entrance.method': 'Assessment method',
  'entrance.passingScore': 'Passing score',
  'entrance.yes': 'Yes',
  'entrance.no': 'No',
  'entrance.unitOrCourse': 'Unit / Course',

  // Tooltips - Assessment Details
  'entrance.highestScore_Info':
    'The highest score that can be obtained from this exam.',
  'entrance.ABSOLUTE_Info':
    'It is a type of evaluation that defines the success of the candidate according to a specified passing score.',
  'entrance.randomGuessing_Info':
    'To discourage random guessing, each incorrect answer given by candidates in multiple-choice questions is scored negatively based on the number of choices for that question. For example, if a question with five options and worth 10 points is answered incorrectly by making a guess; The scores of the question are divided into 5-1=4 slices and one slice, namely -2.5 negative points is applied.',

  // Proctoring Details
  'entrance.CANDIDATE_VERIFICATION': 'Candidate verification required',
  'entrance.FORCE_FULL_SCREEN': 'Full screen required',
  'entrance.NO_NEW_TABS': 'Only one tab',
  'entrance.DISABLE_RIGHT_CLICK': 'Right click disabled',
  'entrance.DISABLE_DEVTOOLS': 'Developer tools are disabled',
  'entrance.RECORD_VIDEO': 'Video recording required',
  'entrance.FORCE_PC': 'Accessible by computer only',
  'entrance.DETECT_PRINT_SCREEN': 'Screen capture disabled',
  'entrance.DISABLE_CLIPBOARD': 'Cut, copy, paste disabled',
  'entrance.DETECT_VM': 'Virtual machine are forbidden',
  'entrance.RECORD_WEB_TRAFFIC': 'Network traffic tracking required',
  'entrance.DETECT_MULTI_SCREEN': 'Multi screens are disabled',
  'entrance.DISABLE_PRINTING': 'Unprintable',
  'entrance.PREVENT_RE_ENTRY': 'Re-login disabled',
  'entrance.RECORD_SCREEN': 'Screen sharing required',
  'entrance.ONLY_ONE_SCREEN': 'Only one screen',

  // Tooltips - Proctoring Details
  'entrance.CANDIDATE_VERIFICATION_Info':
    'Due to exam rules, your photo approved in the system must match the image taken from you via your camera before and during the exam.',
  'entrance.FORCE_FULL_SCREEN_Info':
    'Due to exam rules, you must use the full screen during the exam.',
  'entrance.NO_NEW_TABS_Info':
    'Due to the exam rules, you must use only one tab during the exam.',
  'entrance.DISABLE_RIGHT_CLICK_Info':
    'Due to exam rules, right click of the mouse will be disabled during the exam.',
  'entrance.DISABLE_DEVTOOLS_Info':
    'Due to exam rules, the use of developer tools will be disabled during the exam.',
  'entrance.RECORD_VIDEO_Info':
    'Due to exam rules, your video recording must be taken during the exam.',
  'entrance.FORCE_PC_Info':
    'Due to exam rules, you must only access the exam with a computer.',
  'entrance.DETECT_PRINT_SCREEN_Info':
    'Due to the exam rules, screen capture will be disabled during the exam.',
  'entrance.DISABLE_CLIPBOARD_Info':
    'Due to exam rules, cut, copy, paste shortcuts will be disabled during the exam.',
  'entrance.DETECT_VM_Info':
    'Due to exam rules, you must not use a virtual machine during the exam.',
  'entrance.RECORD_WEB_TRAFFIC_Info':
    'Due to exam rules, your network traffic must be tracked during the exam.',
  'entrance.DETECT_MULTI_SCREEN_Info':
    'Due to exam rules, you must use only one screen on your device during the exam.',
  'entrance.DISABLE_PRINTING_Info':
    'Due to exam rules, you will not be able to print the exam.',
  'entrance.PREVENT_RE_ENTRY_Info':
    'Due to exam rules, you will not be able to re-login after exiting the exam.',
  'entrance.RECORD_SCREEN_Info':
    'Due to exam rules, you must share the screen during the exam.',
  'entrance.ONLY_ONE_SCREEN_Info':
    'Due to exam rules, you must use only one screen on your device during the exam.',

  // Rubric Details
  'entrance.none': 'None',
  'entrance.absolute': 'Absolute',
  'entrance.criteria': 'CRITERIA',
  'entrance.criteria_Info':
    "Criteria to be evaluated in the candidate's performance.",
  'entrance.performanceMethod_Info':
    "Assessment the candidate's performance against the established criteria.",
  'entrance.gradingNONE_Info':
    'There are no passing criteria for this assessment.',
  'entrance.per': '{per}%',
  'entrance.noDesc': 'No description',
  'entrance.total': '{param} of total grade',
  'entrance.learningObjContent': 'Learning Objective :',
  'entrance.notShared': 'Information about this rubric is not shared.',
};

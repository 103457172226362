export const EXAM_INIT_PROCTORING = {
  NO_NEW_TABS: 'NO_NEW_TABS',
  DISABLE_DEVTOOLS: 'DISABLE_DEVTOOLS',
  RECORD_VIDEO: 'RECORD_VIDEO',
  FORCE_PC: 'FORCE_PC',
  DETECT_PRINT_SCREEN: 'DETECT_PRINT_SCREEN',
  DISABLE_CLIPBOARD: 'DISABLE_CLIPBOARD',
  DETECT_VM: 'DETECT_VM',
  RECORD_WEB_TRAFFIC: 'RECORD_WEB_TRAFFIC',
  DETECT_MULTI_SCREEN: 'DETECT_MULTI_SCREEN',
  DISABLE_PRINTING: 'DISABLE_PRINTING',
  RECORD_SCREEN: 'RECORD_SCREEN',
  CANDIDATE_VERIFICATION: 'CANDIDATE_VERIFICATION',
  ONLY_ONE_SCREEN: 'ONLY_ONE_SCREEN',
  DISABLE_RIGHT_CLICK: 'DISABLE_RIGHT_CLICK',
  PREVENT_RE_ENTRY: 'PREVENT_RE_ENTRY',
  FORCE_FULL_SCREEN: 'FORCE_FULL_SCREEN',
};

export const SESSION_STEPS = {
  CREATED: 'CREATED',
  BROWSER_SETUP: 'BROWSER_SETUP',
  GET_READY: 'GET_READY',
  AGREEMENT_APPROVAL: 'AGREEMENT_APPROVAL',
  PROCTORING_APPROVAL: 'PROCTORING_APPROVAL',
  SYSTEM_CHECK: 'SYSTEM_CHECK',
  ID_CHECK: 'ID_CHECK',
  // AUDIO_RECORD: "AUDIO_RECORD",  // Hide voice recording step
  FINAL_PROMPT: 'FINAL_PROMPT',
  ONGOING: 'ONGOING',
  CANDIDATE_VERIFICATION: 'CANDIDATE_VERIFICATION',
};

export const SYSTEM_CHECKS = {
  AUDIO_CHECK: 'AUDIO_CHECK',
  CAMERA_CHECK: 'CAMERA_CHECK',
  INTERNET_CHECK: 'INTERNET_CHECK',
  SCREEN_SHARE_CHECK: 'SCREEN_SHARE_CHECK',
  ONLY_ONE_SCREEN: 'ONLY_ONE_SCREEN',
  ONE_SCREEN_CONTROL: 'ONE_SCREEN_CONTROL',
  ACCESS_BY_COMPUTER: 'ACCESS_BY_COMPUTER',
  VIRTUAL_MACHINE: 'VIRTUAL_MACHINE',
};

// ** React Imports
import React from 'react';

// ** Custom Components
import NotificationDropdown from './Notification/Notifications';
import ActiveSessionBadge from './ActiveSessionBadge';
import UserDropdown from './user/UserDropdown';

import LanguageDropdown from './Language/LanguageDropdown';

class NavbarUser extends React.PureComponent {
  state = {
    langDropdown: false,
  };

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown });

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <ActiveSessionBadge />
        <LanguageDropdown isOnNavbar />
        <NotificationDropdown />
        <UserDropdown {...this.props} />
      </ul>
    );
  }
}

export default NavbarUser;

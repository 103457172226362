import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getAssessmentDateFormat } from '../../../../utility/dateUtil';

const NotificationSubtitle = ({ timeStamp, type }) => {
  const accountNotification = 'FACE_ID_VERIFICATION';

  return (
    <React.Fragment>
      <span className="date">{getAssessmentDateFormat(timeStamp)}</span>
      <span className="dot-seperator"></span>
      <span className="notification-type">
        {type === accountNotification ? (
          <FormattedMessage id="notification.account" />
        ) : (
          <FormattedMessage id="notification.exam" />
        )}
      </span>
    </React.Fragment>
  );
};

export default NotificationSubtitle;

export default {
    "menu.dashboard": "Dashboard",
    "menu.dashboards": "Dashboards",
    "menu.home": "Home",
    "menu.accountDetails": "Account Details",
    "menu.settings": "Settings",
    "menu.globalSettings": "Global Settings",
    "menu.generalSettings": "General Settings",
    "menu.structuralSettings": "Structural Settings",
    "menu.general": "General",
    "menu.structural": "Structural",
    "menu.units": "Units",
    "menu.periods": "Periods",
    "menu.exams": "Exams",
    "menu.examEntrance": "Exam Entrance",
    "menu.examEntrance_examName": "{examName} Entrance",
    "menu.examInformation": "Exam Information",
    "menu.examInformation_examName": "{examName} Information",
    "menu.examReport": "Candidate Result Report",
    "menu.examReport_candidateName": "{candidateName} Result Report",
    "menu.questionFields": "Question Fields",
    "menu.accounts": "Accounts",
    "menu.users": "Users",
    "menu.groups": "Groups",
    "menu.roles": "Roles",
    "menu.assessment": "Assessment",
    "menu.myUnits": "My Units",
    "menu.myCourses": "My Courses",
    "menu.help": "Help",
    "menu.newTicket": "New Ticket",
    "menu.raiseSupport": "Raise Support",
    "menu.faq": "FAQ",
    "menu.about": "About",
    "menu.myAssessments": "My Assessments",
    "menu.welcome": "Welcome",
    "menu.overview": "Overview",
    "menu.profile": "Profile",
};

import { isNullOrEmpty } from '../utility/collectionUtil';
import { ReactComponent as ScreenShare } from '../assets/img/components/violationLayer/screen-share.svg';
import { ReactComponent as DeviceDetection } from '../assets/img/components/violationLayer/device-detection.svg';
import { ReactComponent as ScreenCapture } from '../assets/img/components/violationLayer/screen-capture.svg';
import { ReactComponent as ExamodLogo } from '../assets/img/components/violationLayer/extension-disabled-examod-logo.svg';
import { Maximize, Mic, Video } from 'react-feather';
import { ReactComponent as ProctoringRR } from '../assets/img/exam-init/proctoring-rule-rr.svg';
import { ReactComponent as ProctoringLR } from '../assets/img/exam-init/proctoring-rule-lr-emerald.svg';
import { ReactComponent as ProctoringUnproctored } from '../assets/img/exam-init/proctoring-rule-unproctored.svg';

export const VIOLATION_LAYER_ICONS = {
  SCREEN_CAPTURE: ScreenCapture,
  FULL_SCREEN: Maximize,
  FORCE_PC: DeviceDetection,
  RECORD_SCREEN: ScreenShare,
  RECORD_VIDEO: Video,
  RECORD_MIC: Mic,
  EXTENSION_DISABLED: ExamodLogo,
};

export const VIOLATION_LAYER_TYPES = {
  SCREEN_CAPTURE: 'SCREEN_CAPTURE',
  FULL_SCREEN: 'FULL_SCREEN',
  FORCE_PC: 'FORCE_PC',
  RECORD_SCREEN: 'RECORD_SCREEN',
  RECORD_VIDEO: 'RECORD_VIDEO',
  RECORD_MIC: 'RECORD_MIC',
  NO_NEW_TABS: 'NO_NEW_TABS',
  ONLY_ONE_SCREEN: 'ONLY_ONE_SCREEN',
  EXTENSION_DISABLED: 'EXTENSION_DISABLED',
};

const ProctoringOptions = {
  disableClipboard: 'DISABLE_CLIPBOARD',
  disableRightClick: 'DISABLE_RIGHT_CLICK',
  forceFullscreen: 'FORCE_FULL_SCREEN',
  onlyOneScreen: 'ONLY_ONE_SCREEN',
  blockMobileDevices: 'FORCE_PC',
  disabledPrint: 'DISABLE_PRINTING',
  disabledScreenshot: 'DETECT_PRINT_SCREEN',
  recordScreen: 'RECORD_SCREEN',
  recordVideo: 'RECORD_VIDEO',
  noNewTabs: 'NO_NEW_TABS',
  detectVm: 'DETECT_VM',
};

export const VIOLATION_EVENT_STATES = {
  EXAM_INITIATED: 'EXAM_INITIATED',
  EXAM_ENDED: 'EXAM_ENDED',
  CREATE_SESSION_ID: 'CREATE_SESSION_ID',
  EVENT_CAPTURE: 'EVENT_CAPTURE',
};

export const PROCTORING_TYPES = {
  RECORD_REVIEW: 'RECORD_REVIEW',
  LOCKDOWN_REVIEW: 'LOCKDOWN_REVIEW',
  UNPROCTORED: 'UNPROCTORED',
};

export const proctoringTypeIcons = {
  LOCKDOWN_REVIEW: ProctoringLR,
  RECORD_REVIEW: ProctoringRR,
  UNPROCTORED: ProctoringUnproctored,
};

const isProctoringOptionActive = (options, proctOptionName) => {
  if (isNullOrEmpty(options)) {
    return false;
  }

  const optionNames = options.map((option) => {
    return option.name;
  });

  return optionNames.includes(proctOptionName);
};

export const isCopyPasteDisabled = (options) => {
  return isProctoringOptionActive(options, ProctoringOptions.disableClipboard);
};

export const isRightClickDisabled = (options) => {
  return isProctoringOptionActive(options, ProctoringOptions.disableRightClick);
};

export const isForceFullScreenEnabled = (options) => {
  return isProctoringOptionActive(options, ProctoringOptions.forceFullscreen);
};

export const isOnlyOneScreenEnabled = (options) => {
  return isProctoringOptionActive(options, ProctoringOptions.onlyOneScreen);
};

export const isMobileDevicesBlocked = (options) => {
  return isProctoringOptionActive(
    options,
    ProctoringOptions.blockMobileDevices
  );
};
export const isPrintingDisabled = (options) => {
  return isProctoringOptionActive(options, ProctoringOptions.disabledPrint);
};
export const isScreenshotDisabled = (options) => {
  return isProctoringOptionActive(
    options,
    ProctoringOptions.disabledScreenshot
  );
};
export const isScreenRecordEnabled = (options) => {
  return isProctoringOptionActive(options, ProctoringOptions.recordScreen);
};

export const isVideoRecordEnabled = (options) => {
  return isProctoringOptionActive(options, ProctoringOptions.recordVideo);
};

export const assessmentNeedsExtension = (proctoringOptions) => {
  return (
    proctoringOptions?.filter((option) => option?.requiresExtension).length > 0
  );
};

export const checkExtensionExist = (
  sessionData,
  extensionCheckInterval,
  setIsExtensionDisabled
) => {
  if (
    assessmentNeedsExtension(
      sessionData?.sessionInfo?.assessment?.proctoringOptions
    )
  ) {
    let firstValue = 0;
    let extensionCheckHiddenElement;
    extensionCheckInterval = setInterval(() => {
      if (!extensionCheckHiddenElement) {
        extensionCheckHiddenElement = document.querySelector(
          '.extension-check-element'
        );
      }
      setIsExtensionDisabled(extensionCheckHiddenElement?.value === firstValue);
      if (extensionCheckHiddenElement?.value === firstValue) {
        clearInterval(extensionCheckInterval);
      }
      firstValue = extensionCheckHiddenElement?.value;
    }, 3000);
  }
};

import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { eventRecorder } from '../../../data/SessionRepository';
import { EVENT_END, EVENT_VIOLATIONS } from '../../../domain/Assignment';
import * as Sentry from '@sentry/react';
import {
  getEventQueueLocal,
  getLocalExamInitiated,
  setEventQueueLocal,
} from '../../../data/UserLocalDataSource';
import { VIOLATION_EVENT_STATES } from '../../../domain/Proctoring';

const initial = {
  isInitiated: getLocalExamInitiated(),
  isEnded: false,
  sessionId: null,
  event: {
    type: undefined, // NEW_TAB, MULTIPLE_FACE_DETECTED, NO_FACE_DETECTED, UNRECOGNIZED_FACE_DETECTED, FULL_SCREEN_OFF, SCREEN_SHARING_OFF, CAMERA_NOT_FOUND, MIC_NOT_FOUND, VIRTUAL_MACHINE_DETECTED NEW_TAB, MULTIPLE_FACE_DETECTED, NO_FACE_DETECTED, UNRECOGNIZED_FACE_DETECTED, FULL_SCREEN_OFF, SCREEN_SHARING_OFF, CAMERA_NOT_FOUND, MIC_NOT_FOUND, VIRTUAL_MACHINE_DETECTED
    action: undefined, // START - END
  },
};

const TIMEOUT_DELAY = 2000; // run every 2 seconds

const ongoingEvents = [];
let recoverTimeout = null;

const debugInputForManualEndRecovery = [];

export const ViolationContext = createContext(initial);

export const violationReducer = (state, action) => {
  switch (action.type) {
    case VIOLATION_EVENT_STATES.EXAM_INITIATED:
      return { ...state, isInitiated: true };
    case VIOLATION_EVENT_STATES.EXAM_ENDED:
      return {
        ...state,
        isInitiated: false,
        event: { type: undefined, action: undefined },
      };
    case VIOLATION_EVENT_STATES.CREATE_SESSION_ID:
      return { ...state, sessionId: action.payload };
    case VIOLATION_EVENT_STATES.EVENT_CAPTURE:
      if (state.isInitiated) {
        return {
          ...state,
          event: { type: action.payload.type, action: action.payload.action },
        };
      }
      return state;
    default:
      return state;
  }
};

const ViolationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(violationReducer, initial);
  const { isInitiated, event, sessionId } = state;
  const value = { state, dispatch };
  const [eventInLocal, setEventInLocal] = useState(null); // TODO: emre: this will be checked

  useEffect(() => {
    if (getEventQueueLocal()) {
      let events = new Set(getEventQueueLocal());
      ongoingEvents.push(...events);
      recoverTimeout = setInterval(recoveryTask, TIMEOUT_DELAY);
    }
  }, [ongoingEvents, eventInLocal]);

  function sendEvent(eventType, startOfEnd) {
    handleRecoveryForEvent(eventType, startOfEnd);
    fireEventRequest(eventType, startOfEnd);
  }

  async function fireEventRequest(eventType, startOfEnd) {
    if (eventType) {
      await eventRecorder(
        sessionId,
        eventType,
        startOfEnd,
        EVENT_VIOLATIONS[eventType].requiresExtension
      );
    }
  }

  function handleRecoveryForEvent(eventType, startOfEnd) {
    if (!startOfEnd) {
      // meaning that the event is an instant event not a duration. No actions
      return;
    }

    const existingEventIndex = ongoingEvents.indexOf(eventType);
    if (EVENT_END === startOfEnd) {
      // an event that indicates that the violation has ended.
      // Try remove the the start from on going events
      // Since it shouldn't be allowed that the same event should co exists, we can only execute remove for type
      if (existingEventIndex == -1) {
        Sentry.captureMessage(
          `ERROR - Event start is not found on the list.
          This means that either the start of event is not captured or is cleared by recovery task. Some problem here!`
        );
      } else {
        ongoingEvents.splice(existingEventIndex, 1);
        setEventQueueLocal(ongoingEvents);
        if (ongoingEvents.length == 0 && recoverTimeout) {
          // There are no event pending on the list. Clear timeout"
          clearInterval(recoverTimeout);
          // setEventQueueLocal(ongoingEvents)
          recoverTimeout = null;
        }
      }
      return;
    }
    if (existingEventIndex >= 0) {
      return;
    }
    ongoingEvents.push(eventType);
    setEventQueueLocal(ongoingEvents);
    tryStartRecover();
  }

  function tryStartRecover() {
    if (recoverTimeout !== null) {
      return;
    }
    recoverTimeout = setInterval(recoveryTask, TIMEOUT_DELAY);
  }

  function recoveryTask() {
    ongoingEvents.forEach((item, index) => {
      if (checkIfEndedManual(item)) {
        Sentry.captureMessage(
          "WARN - Violation has ended. However we've missed the event to capture it!"
        );
        sendEvent(item, EVENT_END);
      }
    });
  }

  function checkIfEndedManual(eventType) {
    // TODO - We need some actual impl here!
    const index = debugInputForManualEndRecovery.indexOf(eventType);
    const result = index >= 0;
    if (result) {
      debugInputForManualEndRecovery.splice(index, 1);
    }
    return result;
  }

  useEffect(() => {
    //fetch call for each type
    if (isInitiated && event.type && event.action) {
      sendEvent(event.type, event.action);
    } else if (ongoingEvents) {
      sendEvent(event.type, event.action);
    }
  }, [isInitiated, event.type, event.action, sessionId]);

  return (
    <ViolationContext.Provider value={value}>
      {children}
    </ViolationContext.Provider>
  );
};

const useViolation = () => {
  const violation = useContext(ViolationContext);
  if (violation === undefined) {
    throw new Error('useViolation should be used inside violationContext');
  }
  return violation;
};

export { useViolation, ViolationProvider };

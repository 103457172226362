export default {
  'assessmentVerification.title': 'Aday Yüz Tanıma',
  'assessmentVerification.desc':
    'Bu fotoğraf, sınav öncesi ve sınav sırasında, sistemde onaylanan fotoğrafınız ile karşılaştırılacaktır.',
  'assessmentVerification.cameraView': 'Kamera Görünümünüz',
  'assessmentVerification.RecognitionResult': 'Yüz Tanıma Sonucunuz',
  'assessmentVerification.instructionFacePlace':
    'Yüzünüzü kamera görüntüsündeki kalıba yerleştirin.',
  'assessmentVerification.autoCaptureInfo':
    'Ön cepheden fotoğrafınız Examod tarafından otomatik olarak çekilecektir. ',
  'assessmentVerification.instructionViewCapture':
    'Bu fotoğraf <b>sınav öncesinde</b> ve <b>sınav sonrasında</b> sistemdeki onaylı fotoğrafınız ile karşılaştırılacaktır.',

  'assessmentVerification.agreementSwitchLabel':
    'Examod tarafından kayıt edilen fotoğraf ve videomun daha sonra incelenmesi şartıyla sınava devam etmek istiyorum. Aday uyuşmazlığı olması durumunda<span>tüm sorumluluğu</span>kabul ediyorum.',
  'assessmentVerification.candidateApproved': 'Aday onaylandı',
  'assessmentVerification.waitingCamera': 'Kamera bekleniyor',
  'assessmentVerification.processing': 'İşleniyor',
  'assessmentVerification.faceNotVisible': 'Yüz görünmüyor',
  'assessmentVerification.notRecognized': 'Tanınmadı',

  'assessmentVerification.yourCameraView': 'Kamera Görünümünüz',
  'assessmentVerification.clickToVerifyDescription': `Yüz tanıma işleminizi gerçekleştirmeye hazır olduğunuzda {Verify} düğmesine tıklayın.`,
  'assessmentVerification.verify': 'Doğrula',
  'assessmentVerification.verifyQuote': '"Doğrula"',

  'assessmentVerification.cameraCardTitle': 'Kamera Görünümünüz',

  'assessmentVerification.yourFaceNotIdentified': `Yüz kimliğiniz doğrulanamadı. Lütfen sınava başlamadan önce aşağıdaki yönergeleri takip ederek video kaydı yapınız.`,
  'assessmentVerification.yourFaceNotIdentifiedToast': `Yüz kimliğiniz doğrulanamadı. Lütfen sınava başlamadan önce yönergeleri takip ederek video kaydı yapınız.`,

  'assessmentVerification.Record': 'Kayıt',
  'assessmentVerification.reRecord': 'Tekrar Kaydet',
  'assessmentVerification.whenYouAreReady': `Hazır olduğunuzda, {Record} düğmesine tıklayın ve <b>aşağıdaki cümleyi tekrarlayın</b>. Video kaydı otomatik olarak 10 saniye sürecektir.`,
  'assessmentVerification.amReadyForExam':
    'Merhaba, Ben {name} {surname}. Sınava başlamaya hazırım.',
  'assessmentVerification.Recording': 'Kaydediliyor',

  'assessmentVerification.yourVideoNotRecorded': 'Videonuz kaydedilmedi',
  'assessmentVerification.checkCamera':
    'Lütfen kamera ayarlarınızı kontrol ederek tekrar deneyin.',

  'assessmentVerification.notStarted': 'Başlamadı',
  'assessmentVerification.recorded': 'Kaydedildi',
  'assessmentVerification.notRecorded': 'Kaydedilmedi',
  'assessmentVerification.record_text': '"Kayıt"',

  'assessmentVerification.yourFaceSuccessfullyIdentified': `Yüz kimliğiniz başarıyla doğrulandı. Lütfen sınava başlamadan önce aşağıdaki yönergeleri takip ederek video kaydı yapınız.`,
  'assessmentVerification.errorCameraPermission':
    'Sınav öncesi yüz tanıma işlemini tamamlamak için, tarayıcınızın kamera/mikrofon erişimine izin vermelisiniz.',

  'assessmentVerification.refreshThePage':
    'Yüz tanıma adımında bir sorun yaşıyorsanız sayfayı yeniden yükleyebilirsiniz.',
};

import React from 'react';
import * as Icon from 'react-feather';
import { PATHS } from '../navigation/Paths';

const navigationConfig = [
  {
    type: 'groupHeader',
    groupTitle: 'menu.welcome',
  },
  {
    id: 'overview',
    title: 'menu.overview',
    type: 'item',
    icon: <Icon.Home size={20} />,
    navLink: PATHS.dashboard,
  },
  {
    type: 'groupHeader',
    groupTitle: 'menu.assessment',
  },
  {
    id: 'myAssessments',
    title: 'menu.myAssessments',
    type: 'item',
    navLink: PATHS.myAssessments,
    icon: <Icon.Layers size={20} />,
  },
  {
    type: 'groupHeader',
    groupTitle: 'menu.help',
  },
  {
    id: 'newTicket',
    title: 'menu.newTicket',
    type: 'item',
    icon: <Icon.Send size={20} />,
    navLink: PATHS.newTicket,
  },
  {
    id: 'raiseSupport',
    title: 'menu.raiseSupport',
    icon: <Icon.LifeBuoy size={20} />,
    type: 'external-link',
    newTab: true,
    navLink: 'https://examod.com/',
  },
];

export default navigationConfig;

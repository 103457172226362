export default {
  'entrance.title': 'Sınav Bilgileri',
  'entrance.description': 'Değerlendirme Açıklaması',
  'entrance.overdueToast':
    'Yalnızca devam etmekte olan ve tamamlanmayan sınavlara giriş yapılabilir. Bir problem olduğunu düşünüyorsanız sistem yetkilisine başvurun.',
  'entrance.details': 'Değerlendirme Detayları',
  'entrance.proctoringDetails': 'Sınav Gözetim Kuralları',
  'entrance.rubricDetails': 'Dereceli Puanlama Anahtarı Detayları ({name})',
  'entrance.chromeRequired': 'Examod Proctoring Eklentisi Gerekli - {dowload}',
  'entrance.examodProctoringExtension': 'Examod Proctoring Eklentisi Gerekli',
  'entrance.dowload': 'İndir',
  'entrance.enterExam': 'Sınava Gir',
  'entrance.emptyDesc': 'Herhangi bir açıklama bulunmamaktadır.',
  'entrance.leftTime':
    'Sınav kontrol adımlarına, sınav başlama zamanından en erken <b>15 dakika</b> önce erişebilirsiniz.',
  'entrance.extensionAndBrowser':
    'Bu sınav <b>Google Chrome</b> web tarayıcısı ve <b>Examod Proctoring</b> eklentisi gerektirmektedir.',
  'entrance.browserControl':
    'Bu sınav Google Chrome web tarayıcısı gerektirmektedir. Sınava giriş yapmak için Google Chrome web tarayıcısı kullanın.',

  // Assessment Details
  'entrance.tilteDetails': 'Detaylar',
  'entrance.titleDescription': 'Açıklama',
  'entrance.type': 'Sınav türü',
  'entrance.category': 'Kategorisi',
  'entrance.examStart': 'Başlama tarihi',
  'entrance.examEnd': 'Bitiş tarihi',
  'entrance.duration': 'Süre',
  'entrance.highestScore': 'En yüksek puan',
  'entrance.passingCriteria': 'Geçme ölçütü',
  'entrance.randomGuessing': 'Rastgele tahmin düzeltmesi',
  'entrance.method': 'Değerlendirme yöntemi',
  'entrance.passingScore': 'Geçme puanı',
  'entrance.yes': 'Evet',
  'entrance.no': 'Hayır',
  'entrance.unitOrCourse': 'Birim / Ders',

  // Tooltips - Assessment Details
  'entrance.highestScore_Info':
    'Bu sınavdan alınabilecek en yüksek puan değeri.',
  'entrance.ABSOLUTE_Info':
    'Belirlenmiş bir geçme puanına göre adayın başarılı olma durumunu tanımlayan değerlendirme türüdür.',
  'entrance.randomGuessing_Info':
    'Rastgele tahmin etmeyi caydırmak için, adayların çoktan seçmeli sorularda verdikleri her bir yanlış yanıt için o sorunun seçenek sayısına göre negatif puanlama yapılır. Örneğin, beş seçenekli ve 10 puan değerindeki bir soruya tahmin yapılarak yanlış yanıt verildiğinde; sorunun puanları 5-1=4 dilime ayrılır ve bir dilim yani -2.5 negatif puan uygulanır.',

  // Proctoring Details
  'entrance.CANDIDATE_VERIFICATION': 'Aday doğrulama gerekli',
  'entrance.FORCE_FULL_SCREEN': 'Tam ekran zorunlu',
  'entrance.NO_NEW_TABS': 'Sadece tek sekme',
  'entrance.DISABLE_RIGHT_CLICK': 'Sağ tık yapılamaz',
  'entrance.DISABLE_DEVTOOLS': 'Geliştirici araçları kullanılamaz',
  'entrance.RECORD_VIDEO': 'Video kaydı gerekli',
  'entrance.FORCE_PC': 'Sadece bilgisayarla girilebilir',
  'entrance.DETECT_PRINT_SCREEN': 'Ekran yakalama yapılamaz',
  'entrance.DISABLE_CLIPBOARD': 'Kes, kopyala, yapıştır yapılamaz',
  'entrance.DETECT_VM': 'Sanal makine kullanılamaz',
  'entrance.RECORD_WEB_TRAFFIC': 'Ağ trafiği takibi gerekli',
  'entrance.DETECT_MULTI_SCREEN': 'Birden çok ekran kullanılamaz',
  'entrance.DISABLE_PRINTING': 'Yazdırılamaz',
  'entrance.PREVENT_RE_ENTRY': 'Yeniden giriş yapılamaz',
  'entrance.RECORD_SCREEN': 'Ekran paylaşımı gerekli',
  'entrance.ONLY_ONE_SCREEN': 'Yalnızca bir ekran',

  // Tooltips - Proctoring Details
  'entrance.CANDIDATE_VERIFICATION_Info':
    'Sınav kuralları gereği, sistemde adınıza onaylı fotoğrafınızın, sınav öncesi ve sırasında kameranız yoluyla sizden alınan görüntü ile uyumlu bir biçimde eşleşmesi gerekmektedir.',
  'entrance.FORCE_FULL_SCREEN_Info':
    'Sınav kuralları gereği, sınav süresince tam ekran kullanmanız gerekmektedir.',
  'entrance.NO_NEW_TABS_Info':
    'Sınav kuralları gereği, sınav süresince yalnızca tek sekme kullanmanız gerekmektedir.',
  'entrance.DISABLE_RIGHT_CLICK_Info':
    'Sınav kuralları gereği, sınav süresince farenin sağ tuşuna tıklanması devre dışı bırakılacaktır.',
  'entrance.DISABLE_DEVTOOLS_Info':
    'Sınav kuralları gereği, sınav süresince geliştirici araçlarının kullanımı devre dışı bırakılacaktır.',
  'entrance.RECORD_VIDEO_Info':
    'Sınav kuralları gereği, sınav süresince video kaydınızın alınması gerekmektedir.',
  'entrance.FORCE_PC_Info':
    'Sınav kuralları gereği, sınava yalnızca bilgisayar ile giriş yapmanız gerekmektedir.',
  'entrance.DETECT_PRINT_SCREEN_Info':
    'Sınav kuralları gereği, sınav süresince ekran yakalama devre dışı bırakılacaktır.',
  'entrance.DISABLE_CLIPBOARD_Info':
    'Sınav kuralları gereği, sınav süresince kes, kopyala, yapıştır kısayolları devre dışı bırakılacaktır.',
  'entrance.DETECT_VM_Info':
    'Sınav kuralları gereği, sınav süresince sanal makine kullanmamanız gerekmektedir.',
  'entrance.RECORD_WEB_TRAFFIC_Info':
    'Sınav kuralları gereği, sınav süresince ağ trafiğinizin takibi gerekmektedir.',
  'entrance.DETECT_MULTI_SCREEN_Info':
    'Sınav kuralları gereği, sınav süresince cihazınızda yalnızca bir ekran kullanmanız gerekmektedir.',
  'entrance.DISABLE_PRINTING_Info':
    'Sınav kuralları gereği, sınavı yazdırmanız mümkün olmayacaktır.',
  'entrance.PREVENT_RE_ENTRY_Info':
    'Sınav kuralları gereği, sınavdan çıkış yaptıktan sonra tekrar giriş yapmanız mümkün olmayacaktır.',
  'entrance.RECORD_SCREEN_Info':
    'Sınav kuralları gereği, sınav süresince ekran paylaşımı yapmanız gerekmektedir.',
  'entrance.ONLY_ONE_SCREEN_Info':
    'Sınav kuralları gereği, sınav süresince cihazınızda yalnızca bir ekran kullanmanız gerekmektedir.',

  // Rubric Details
  'entrance.none': 'Yok',
  'entrance.absolute': 'Mutlak',
  'entrance.criteria': 'ÖLÇÜT',
  'entrance.criteria_Info': 'Adayın performansında değerlendirilecek ölçütler.',
  'entrance.performanceMethod_Info':
    'Adayın performansını belirlenen kriterlere göre değerlendirin.',
  'entrance.gradingNONE_Info':
    'Bu değerlendirme için herhangi bir geçme kriteri bulunmamaktadır.',
  'entrance.per': '%{per}',
  'entrance.noDesc': 'Açıklama yok',
  'entrance.total': ' Toplam puana etkisi: {param}',
  'entrance.learningObjContent': 'Öğrenme Hedefi :',
  'entrance.notShared':
    'Bu dereceli puanlama anahtarıyla ilgili bilgi paylaşılmamaktadır.',
};

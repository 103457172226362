export default {
  "voiceCheck.title": "Taking the Candidate's Voice Recording",
  "voiceCheck.desc":
    "Your voice recording will be taken to be used in the proctorings that will take place during the exam.",
  "voiceCheck.recordText":
    "When you are ready, press the ' {record} ' button, count from 1 to 10  in understandable voice.",
  "voiceCheck.readyText":
    "'Hello, my name is {nameSurname}. I'm ready for the exam.'",
  "voiceCheck.record": "Record",
  "voiceCheck.repeat": "Repeat",
};

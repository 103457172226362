export default {
  'login.login': 'Giriş Yap',
  'login.email': 'E-mail',
  'login.emailPlaceholder': 'info@examod.com',
  'login.password': 'Şifre',
  'login.remember': 'Beni hatırla',
  'login.forgotPass': 'Şifremi unuttum',
  'login.welcome': "Examod'a Hoş geldiniz!",
  'login.organizationPlaceholder': 'Kuruluşunuzu seçin',
  'login.organization': 'Kuruluş',
  'login.digitalAssessment': 'Dijital Sınav ve Değerlendirme Platformu',
  'login.digitalAssessmentSubtitle': 'Akıllı, Güvenli, Sürdürülebilir',
  'login.policy':
    'Hizmetimizin ve web sitemizin kullanımı <terms>Şartlar ve Koşullar</terms> ve <privacy>Gizlilik Politikamıza</privacy> tabidir.',
  'login.copyright':
    '	Copyright {copyrightSymbol} {year} <b>Examod</b>, Tüm hakları saklıdır.',
  'login.emailPatternError': 'Lütfen geçerli bir e-posta girin',
  'login.requiredError': 'Bu alan boş bırakılamaz.',
  'login.loginError': 'Girmiş olduğunuz email ya da şifre hatalı.',
  'login.next': 'Devam',
  'login.redirecting': 'Yönlendiriliyor',
  'login.redirectingExplanation':
    'Bu sayfa otomatik olarak organizasyonunuzun Examod uygulamasına yönlendirilecektir. Lütfen sayfayı yenilemeyin veya tarayıcınızın <b>“Geri”</b> veya <b>“Kapat”</b> düğmesine tıklamayın.',
  'login.redirectManuel':
    'Yönlendirme gerçekleşmezse, manuel olarak gitmek için lütfen <b>buraya tıklayın.</b>',
  'login.redirectErrorTitle':
    'Hay Aksi! Giriş yapılırken bir sorunla karşılaşıldı.',
  'login.toLogin': 'Giriş sayfasında dön',
};

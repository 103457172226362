// ** React imports
import React from 'react';

// ** Third Party
import { Card, CardBody, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

// ** Components
import SecondaryButton from '../../../../components/examod/buttons/secondaryButton';

// ** Navigator
import { navigateToLogin } from '../../../../Navigator';

// ** Assets
import './../../../../assets/scss/pages/fe-based-error.scss';
import errorImg from '../../../../assets/img/pages/fe-based-error.png';

const FeBasedError = ({ loginErrorTitle, loginErrorMessage }) => {
  return (
    <div className="error-page-fe">
      <img
        className="background-images-fe"
        src={require('../../../../assets/img/pages/error-page-top-left-cloud.svg')}
        style={{ position: 'absolute', top: '0', left: '0' }}
        alt="cloud"
      />
      <img
        className="background-images-fe"
        src={require('../../../../assets/img/pages/error-page-top-right-image.svg')}
        style={{ position: 'absolute', top: '0', right: '0' }}
        alt="imagewithsunset"
      />
      <img
        className="background-images-fe"
        src={require('../../../../assets/img/pages/error-page-bottom-right-cloud.svg')}
        style={{ position: 'absolute', bottom: '0', right: '0' }}
        alt="cloud"
      />
      <Row className="m-0">
        <Col sm="12">
          <Card className="auth-card bg-transparent shadow-none rounded-0 mb-0 w-100 d-flex justify-content-center align-items-center">
            <CardBody className="error-page-container-fe text-center">
              <img
                src={errorImg}
                alt="ErrorImg"
                className="img-fluid align-self-center"
              />
              <div className="error-title-fe">
                <FormattedMessage
                  id={loginErrorTitle || 'feBasedError.title'}
                  values={{
                    color: (chunks) => (
                      <span className="span-examod">{chunks}</span>
                    ),
                  }}
                />
              </div>
              <div className="error-description-fe">
                {loginErrorMessage || (
                  <FormattedMessage id="feBasedError.desc" />
                )}
              </div>
              {loginErrorMessage ? (
                <SecondaryButton
                  className="redirect-error-button"
                  type="button"
                  textId="login.toLogin"
                  onClick={() => {
                    navigateToLogin();
                  }}
                />
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default FeBasedError;

export default {
  'myAssessments.header': 'My Assessments',
  'myAssessments.information': 'List of all assessments assigned to you',
  'myAssessments.name': 'Assessment Name',
  'myAssessments.status': 'Status',
  'myAssessments.actions': 'Actions',
  'myAssessments.searchFilter': 'Search Filter',
  'myAssessments.search': 'Search',
  'myAssessments.selectStatus': 'Select',
  'myAssessments.selectAssessmentType': 'Select Type',
  'myAssessments.selectAssessmentCategory': 'Select Category',
  'myAssessments.examEntrance': 'Exam Entrance',
  'myAssessments.examInformation': 'Exam Information',
  'myAssessments.assessmentType': 'Assessment Type',
  'myAssessments.report': 'Exam Report',
  'myAssessments.type': 'Type',
  'myAssessments.category': 'Category',
  'myAssessments.assessmentCategory': 'Assessment Category',
  'myAssessments.duration': 'Duration',
  'myAssessments.dates': 'Start & End Time',
  'myAssessments.unit': 'Unit / Course',
  'myAssessments.switchText': 'Show also expired and invalidated assessments',
  'myAssessments.reviewResponse': 'Review Questions and Answers',
};

export default {
  'assessmentStatus.PAPER_BASED': 'Kağıt Tabanlı',
  'assessmentStatus.PAPER_BASED_CLASSIC': 'Klasik Kağıt Tabanlı',
  'assessmentStatus.ON_SITE': 'Yerinde / Test Merkezinde',
  'assessmentStatus.PERFORMANCE_EXAM': 'Performans',
  'assessmentStatus.ONLINE': 'Çevrim İçi',
  'assessmentStatus.NONE': 'Yok',
  'assessmentStatus.ABSOLUTE': 'Mutlak',
  'assessmentStatus.GLOBAL_RATING':
    'Genel değerlendirme (Yalnızca dereceli puanlama anahtarında)',
  'assessmentStatus.BELL_CURVE': 'Bağıl (Çan Eğrisi)',
  'assessmentStatus.MANUAL': 'Elle değerlendirme',
  'assessmentStatus.RUBRIC': 'Dereceli puanlama anahtarı',
  'assessmentStatus.ON_SITEShort': 'Yerinde',
  'assessmentStatus.OSCE': 'OSCE',
  'assessmentStatus.FINISHED': 'Tamamlandı',
  'assessmentStatus.ONGOING': 'Devam ediyor',
  'assessmentStatus.NOT_STARTED': 'Başlamadı',
  'assessmentStatus.CANCELLED': 'İptal edildi',
  'assessmentStatus.unknown': 'Bilinmiyor',
  'assessmentStatus.INVALID': 'Geçersiz',
  'assessmentStatus.COMPLETED': 'Tamamlandı',
};

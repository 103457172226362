export default {
  "voiceCheck.title": "Adayın Ses Kaydının Alınması",
  "voiceCheck.desc":
    "Ses kaydınız sınav süresince gerçekleşecek denetlemelerde kullanılmak üzere alınacaktır.",
  "voiceCheck.recordText":
    "Hazır olduğunuzda ' {record} ' butonuna basarak 1'den 10'a kadar anlaşılır bir biçimde sayınız.",
  "voiceCheck.readyText":
    "'Merhaba, benim adım {nameSurname}. Sınav için hazırım.'",
  "voiceCheck.record": "Kayıt",
  "voiceCheck.repeat": "Tekrarla",
};

import axios from 'axios';
import { BASE_URL, HEADERS } from '../configs/apiConfig';
import { getLocalDeviceInfo } from '../data/UserLocalDataSource';

export const httpClient = axios.create({
  baseURL: BASE_URL,
  headers: HEADERS,
});
export const setAuthToken = (token) => {
  if (token) {
    httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }
};
export const dropAuthToken = () => {
  delete httpClient.defaults.headers.common['Authorization'];
};

export const setLanguage = (lang) => {
  httpClient.defaults.headers.common['X-Language'] = lang.toLowerCase();
};

export const setDeviceId = (id) => {
  let deviceId = getLocalDeviceInfo()?.deviceId
    ? getLocalDeviceInfo()?.deviceId
    : id;
  httpClient.defaults.headers.common['X-Device-Id'] = deviceId;
};

export const dropExtensionHeader = () => {
  delete httpClient.defaults.headers.common['X-Client-Type'];
};

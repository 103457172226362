import React, { useContext, useEffect, useState } from 'react';

// ** Third party
import Countdown, { zeroPad } from 'react-countdown-now';
import { Clock } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

// ** Data
import { removeSessionInfo } from '../../../data/UserLocalDataSource';
import { MainContext } from '../../../store/Store';

// ** Style
import '../../../assets/scss/components/active-session.scss';

const ActiveSessionBadge = () => {
  const [examEndDate, setExamEndDate] = useState('');
  const [showRemainingTime, setShowRemainingTime] = useState(false);

  const { state, dispatch } = useContext(MainContext);

  // ** Hooks
  useEffect(() => {
    if (state?.sessionInfo) {
      handleLocaleSessionInfo();
    }
  }, [state?.sessionInfo]);

  const handleLocaleSessionInfo = () => {
    const sessionInfo = state.sessionInfo;
    if (sessionInfo) {
      const endDate =
        sessionInfo?.examEndDate ||
        new Date(sessionInfo?.assessment?.endDate * 1000);
      const isExamExpired = new Date(endDate) < new Date();
      setExamEndDate(endDate);
      if (isExamExpired) {
        deleteSessionInfo();
      } else {
        setShowRemainingTime(true);
      }
    }
  };

  const onCompleteCountdown = () => {
    deleteSessionInfo();
  };

  const deleteSessionInfo = () => {
    removeSessionInfo();
    dispatch({ sessionInfo: null });
    setShowRemainingTime(false);
  };

  const showActiveSessionModal = () => {
    dispatch({ sessionInfo: { ...state.sessionInfo, showModal: true } });
  };

  const renderer = ({ days, hours, minutes, seconds }) => {
    const hoursWithDays = hours + days * 24;
    return (
      <div
        className={classnames('active-session-remaining-time', {
          'no-hours': !hoursWithDays,
          'has-three-digit-hours': hoursWithDays > 99,
        })}
      >
        {hoursWithDays ? `${zeroPad(hoursWithDays)}:` : null}
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </div>
    );
  };

  return showRemainingTime && state.sessionInfo ? (
    <div
      className="active-session-wrapper"
      onClick={() => {
        showActiveSessionModal();
      }}
    >
      <div className="active-session-dot" />
      <div className="active-session-text">
        <FormattedMessage id="session.activeSession" />
      </div>
      <div className="active-session-seperator" />
      <Clock size={15} />
      <Countdown
        date={examEndDate}
        renderer={renderer}
        onComplete={() => onCompleteCountdown()}
      />
    </div>
  ) : null;
};

export default ActiveSessionBadge;

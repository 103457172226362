import React from 'react';
import classnames from 'classnames';
import themeConfig from '../configs/themeConfig';

import { FormattedMessage } from 'react-intl';

import logoTextWhite from '../assets/img/logo/logo-yazili-w.png';
import logoWhite from '../assets/img/logo/logo-dash-w.png';

function LoginLayout({ children, ...rest }) {
  return (
    <div
      className={classnames('login-layout-wrapper', {
        'layout-dark': themeConfig.layoutDark,
      })}
    >
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="flexbox-container">
              <div className="left-content-container">
                <div className="gradient-background"></div>
                <div className="left-content">
                  <div className="logos">
                    <img
                      src={logoWhite}
                      alt="examod-logo-white"
                      className="logo"
                      height="63px"
                      width="58px"
                    />
                    <img
                      src={logoTextWhite}
                      alt="examod-logo-text-white"
                      className="logo-text"
                      height="54px"
                      width="200px"
                    />
                  </div>
                  <div className="titles">
                    <h1>
                      <FormattedMessage id={'login.digitalAssessment'} />
                    </h1>
                    <h2>
                      <FormattedMessage
                        id={'login.digitalAssessmentSubtitle'}
                      />
                    </h2>
                  </div>
                </div>
              </div>{' '}
              <div className="right-content-container">
                <main className="main w-100 h-100 d-flex flex-column align-items-center">
                  {children}
                  <footer className="login-footer-wrapper mb-1">
                    <div className="login-footer">
                      <FormattedMessage
                        id={'login.copyright'}
                        values={{
                          copyrightSymbol: <span>&copy;</span>,
                          year: new Date().getFullYear(),
                          b: (chunks) => (
                            <a href="https://examod.com">
                              <strong className="text-red">{chunks}</strong>
                            </a>
                          ),
                        }}
                      />
                    </div>
                  </footer>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginLayout;

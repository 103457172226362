export default {
  'assignmentStatus.passive': 'Pasif',
  'assignmentStatus.ongoing': 'Devam Ediyor',
  'assignmentStatus.overdue': 'Süresi Geçti',
  'assignmentStatus.completed': 'Tamamlandı',
  'assignmentStatus.not_attended': 'Katılmadı',
  'assignmentStatus.not_started': 'Başlamadı',
  'assignmentStatus.unknown': 'Bilinmiyor',
  'assignmentStatus.invalidated': 'Geçersiz',
  'assignmentStatus.cancelled': 'İptal edildi',
};

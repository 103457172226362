export default {
  'time.hour': '{hour} sa',
  'time.min': '{min} dk',
  'time.sec': '{sec} sn',
  'time.hours': 'saat',
  'time.minutes': 'dakika',
  'time.seconds': 'saniye',
  'time.days': 'gün',
  'time.timezone': 'Sistem Zaman Dilimi: {timezone}',
};

export default {
  'assessmentLobby.title': 'Bekleme Odası',
  'assessmentLobby.proctoringInfo':
    'Sınav denetleme kuralları aktif durumdadır. Bekleme odasında da bu kurallara uymanız gerekmektedir. ',
  'assessmentLobby.startInfo':
    'Sınav başlama saati geldiğinde yukarıda yer alan "{start}" butonu aktif olacaktır.',
  'assessmentLobby.start': 'Sınava Başla',
  'assessmentLobby.examInfo': 'Sınav Bilgileri',
  'assessmentLobby.hours': 'Saat',
  'assessmentLobby.minutes': 'Dakika',
  'assessmentLobby.seconds': 'Saniye',
  'assessmentLobby.days': 'Gün',
};

import Cookies from 'js-cookie';
import { convertStringToArray } from '../utility/stringUtil';
import { USER_LOCAL_DATA } from '../constants/UserLocalDataSource';

export const saveToken = (token) => {
  Cookies.set(USER_LOCAL_DATA.token, token, {
    //secure : true,
    expires: 3,
  });
};

export const rememberToken = (token) => {
  localStorage.setItem(USER_LOCAL_DATA.rememberToken, token);
  sessionStorage.setItem(USER_LOCAL_DATA.rememberToken, token);
};

export const getRememberToken = () => {
  return localStorage.getItem(USER_LOCAL_DATA.rememberToken);
};

export const removeRememberToken = () => {
  localStorage.removeItem(USER_LOCAL_DATA.rememberToken);
};

export const removeToken = () => {
  Cookies.remove(USER_LOCAL_DATA.token);
};

export const getToken = () => {
  return Cookies.get(USER_LOCAL_DATA.token);
};

export const saveLang = (lang) => {
  localStorage.setItem(USER_LOCAL_DATA.lang, lang);
  sessionStorage.setItem(USER_LOCAL_DATA.lang, lang);
};

export const getLang = () => {
  return localStorage.getItem(USER_LOCAL_DATA.lang);
};

export const removeLang = () => {
  localStorage.removeItem(USER_LOCAL_DATA.lang);
  sessionStorage.removeItem(USER_LOCAL_DATA.lang);
};

export const saveDeviceInfo = (device) => {
  const data = {
    id: device.id,
    deviceId: device.deviceId,
    deviceName: device.deviceName,
    useId: device.user.id,
  };
  localStorage.setItem(USER_LOCAL_DATA.deviceInfo, JSON.stringify(data));
};

export const getLocalDeviceInfo = () => {
  const device = localStorage.getItem(USER_LOCAL_DATA.deviceInfo);
  return JSON.parse(device);
};

export const setDeviceIdToken = (id) => {
  if (getLocalDeviceInfo()?.deviceId)
    Cookies.set(USER_LOCAL_DATA.deviceId, getLocalDeviceInfo()?.deviceId);
  else {
    Cookies.set(USER_LOCAL_DATA.deviceId, id);
  }
};

export const getDeviceIdToken = () => Cookies.get(USER_LOCAL_DATA.deviceId);

export const getSearchData = (userId) => localStorage.getItem(userId);

export const saveItemToHistory = (userId, titleId, value) => {
  let storage = JSON.parse(getSearchData(userId));
  if (storage) {
    storage[titleId] = value;
  } else {
    storage = {
      [titleId]: value,
    };
  }
  localStorage.setItem(userId, JSON.stringify(storage));
};

export const getItemFromHistory = (userId, titleId) => {
  let storage = JSON.parse(getSearchData(userId));
  return storage[titleId];
};

export const saveLocalExamInitiated = (examIsStarted) => {
  return localStorage.setItem(USER_LOCAL_DATA.examInitiated, examIsStarted);
};
export const getLocalExamInitiated = () => {
  return localStorage.getItem(USER_LOCAL_DATA.examInitiated);
};

export const saveFlaggedQuestion = (questionId) => {
  let flaggedQuestions = getFlaggedQuestions();
  if (flaggedQuestions.includes(questionId)) {
    flaggedQuestions = flaggedQuestions.filter((qId) => qId !== questionId);
  } else {
    flaggedQuestions.push(questionId);
  }
  localStorage.setItem(USER_LOCAL_DATA.flaggedQuestion, flaggedQuestions);
};

export const getFlaggedQuestions = () => {
  const storedFlagged = localStorage.getItem(USER_LOCAL_DATA.flaggedQuestion);
  const flagged = storedFlagged?.length ? storedFlagged?.split(',') : [];
  return flagged.map((id) => parseInt(id));
};

export const removeFlaggedQuestions = () => {
  localStorage.removeItem(USER_LOCAL_DATA.flaggedQuestion);
};

export const saveSessionInfo = (sessionInfo) => {
  localStorage.setItem(
    USER_LOCAL_DATA.sessionInfo,
    JSON.stringify(sessionInfo)
  );
};

export const getSessionInfo = () => {
  const sessionInfo = localStorage.getItem(USER_LOCAL_DATA.sessionInfo);
  return JSON.parse(sessionInfo);
};

export const removeSessionInfo = () => {
  localStorage.removeItem(USER_LOCAL_DATA.sessionInfo);
};

export const saveSidebarState = (state) => {
  localStorage.setItem(USER_LOCAL_DATA.navbarState, state);
};
export const getSidebarState = () => {
  const defaultState = true;
  const navbarState = localStorage.getItem(USER_LOCAL_DATA.navbarState);
  if (navbarState) {
    return navbarState === 'true';
  }
  return defaultState;
};
export const removeSidebarState = () => {
  return localStorage.removeItem(USER_LOCAL_DATA.navbarState);
};

export const setEventQueueLocal = (arr) => {
  localStorage.setItem(USER_LOCAL_DATA.eventQueue, arr);
};

export const getEventQueueLocal = (arr) => {
  const events = localStorage.getItem(USER_LOCAL_DATA.eventQueue);
  return convertStringToArray(events, ',');
};

export const setEliminatedAnswers = (ids) => {
  localStorage.setItem(USER_LOCAL_DATA.eliminatedAnswer, ids);
};

export const getEliminatedAnswers = (ids) => {
  const eliminatedIds = localStorage.getItem(USER_LOCAL_DATA.eliminatedAnswer);
  const eliminatedIdsArr = convertStringToArray(eliminatedIds, ',') || [];
  return eliminatedIdsArr.map((id) => parseInt(id));
};

export const removeEliminatedAnswers = () => {
  localStorage.removeItem(USER_LOCAL_DATA.eliminatedAnswer);
};

export default {
  'notification.ASSESSMENT_COMPLETED': 'The {examName} exam has ended.',
  'notification.ASSESSMENT_RESULT_ANNOUNCED':
    'The results of your {examName} exam have been announced.',
  'notification.ASSESSMENT_ASSIGNED': 'You have been added to {examName} exam.',
  'notification.ASSESSMENT_UPDATED':
    'Made some changes to your {examName} exam.',
  'notification.FACE_ID_VERIFICATION':
    'Your Face ID has been confirmed. You can now take exams that require Face ID.',
  'notification.exam': 'Exam Notification',
  'notification.account': 'Account Notification',
  'notification.count': '{count} New',
  'notification.notifications': 'Notifications',
  'notification.clear': 'Clear All',
  'notification.noNotifications': 'No notifications yet',
};

export default {
  'home.userStatistics': 'User Statistics',
  'home.activeUsers': 'Active Users in the Organization',
  'home.pendingUsers': 'Invitation Pending Users',
  'home.userGroupsCreated': 'User Groups Created',
  'home.rolesDefined': 'Roles Defined in the System',
  'home.assessmentStatistics': 'Assessment Statistics',
  'home.examsPublished': 'Exams Published',
  'home.ongoingExams': 'Ongoing Exams',
  'home.questionCreated': 'Questions Created',
  'home.updatedNow': 'Updated Now',
  'home.actions': 'Actions',
  'home.edit': 'Edit/View User',
  'home.usersInvited': 'Users Invited',
  'home.welcome': 'Welcome,',
  'home.upcomingExams': 'Upcoming and Current Assessments',
  'home.upcomingExamDetails': 'Details',
  'home.checkSystem': 'Check your system for online exams',
  'home.checkSystemButton': 'Check',
  'home.lastExamResults': 'Your Last Exam Results',
  'home.examResultExamName': 'Exam name',
  'home.assessmentType': 'Type',
  'home.examResultCourseName': 'Unit / Course name',
  'home.examResult': 'Exam result',
  'home.examResultActions': 'Actions',
  'home.examResultActionButton': 'Exam Report',
  'home.supportRequests': 'Support Requests',
  'home.supportRequestSubject': 'Subject',
  'home.supportRequestStatus': 'Status',
  'home.supportRequestSolved': 'Solved',
  'home.supportRequestNotSolved': 'Not Solved',
  'home.supportRequestButtonRequests': 'Requests',
  'home.supportRequestButtonCreate': 'Create Request',
  'home.systemUpdates': 'Release Notes and System Updates',
  'home.noUpcomingExams': 'There are no upcoming exams',
  'home.ticketText':
    'You can create a technical support request regarding the problems you experience with Examod. We will try to get back to you as soon as possible.',
  'home.examResultTooltip': 'Candidate Score / Maximum Score',
};

export default {
  'report.assessmentOverview': 'Assessment Overview',
  'report.score': 'Candidate Score',
  'report.candidateOverview': 'Candidate Overview',
  'report.unitCourse': 'Unit / Course',
  'report.examiner': 'Examiner',
  'report.type': 'Assessment type',
  'report.category': 'Assessment category',
  'report.highestScore': 'The highest score',
  'report.highestScore-tooltip':
    'The highest score that can be obtained from this assessment.',
  'report.randomGuessing': 'Random guessing fix',
  'report.randomGuessing-tooltip':
    'To discourage random guessing, each incorrect answer given by candidates in multiple-choice questions is scored negatively based on the number of choices for that question. For example, if a question with five options and worth 10 points is answered incorrectly by making a guess; The scores of the question are divided into 5-1=4 slices and one slice, namely -2.5 negative points is applied.',
  'report.passingCriteria': 'Passing criteria',
  'report.passingCriteria-tooltip':
    'It is a type of evaluation that defines the success of the candidate according to a specified passing score.',
  'report.passingScore': 'Passing score',
  'report.rank': 'Rank',
  'report.quartile': 'Rank quartile',
  'report.duration': 'Duration',
  'report.examStart': 'Exam start time',
  'report.examEnd': 'Exam end time',
  'report.questionCount': 'Questions',
  'report.correctAnswers': 'Correct answers',
  'report.incorrectAnswers': 'Incorrect answers',
  'report.unansweredQuestions': 'Unanswered questions',
  'report.unviewedQuestions': 'Unviewed questions',
  'report.netScore': 'Net score',
  'report.rawAndNet': 'Raw Score / Net Score',
  'report.netScore-tooltip': "Candidate's assessment score",
  'report.rawAndNet-tooltip':
    'The raw score represents the unprocessed score from the assessment, and the net score represents the score with random guessing correction applied. ',
  'report.averageScore': 'Overall net score average',
  'report.topicTitle': 'Topics',
  'report.objTitle': 'Learning Objectives',
  'report.topicName': 'topic name',
  'report.objName': 'learning objective name',
  'report.totalQuestion': 'correct answer / total question',
  'report.totalQuestionQa': 'CORRECT ANSWER / TOTAL QUESTION BY ASSOCIATED QA',
  'report.weight': 'weight (%)',
  'report.weightQa': 'WEIGHT (%) BY ASSOCIATED QA ',
  'report.scoreRange': 'Score Range (%)',
  'report.avgScore': 'Average Score',
  'report.yourScore': 'Candidate Score',
  'report.radarTopicTitle': 'Radar Chart by Topics',
  'report.radarObjTitle': 'Radar Chart by Learning Objectives',
  'report.resultReport': '{nameSurname} Result Report',
  'report.candidate': 'Candidate',
  'report.topicSubtitle': 'ALL TOPICS',
  'report.objSubtitle': 'ALL LEARNING OBJECTIVES',
  'report.yes': 'Yes',
  'report.per': '{per}%',
  'report.assessmentMethod': 'Assessment method',
  'report.rubricName': 'Rubric name',
  'report.criteria': 'Criteria',
  'report.allCriteria': 'All Criteria',
  'report.level': 'Candidate / Overall Level',
  'report.examinerFeedbacks': "Examiner's Feedbacks",
  'report.feedback-tooltip':
    'Written and oral feedbacks from the examiner for the assessment.',
  'report.criteriaTitle': 'Candidate Performance by Criteria',
  'report.criteriaSubtitle': 'Review candidate performance by criteria',
  'report.RUBRIC': 'Rubric',
  'report.assessmentName': 'Assessment name',
  'report.candidateName': 'Name surname',
  'report.assessmentStatus': 'Assessment status',
  'report.status': 'Status',
  'report.description': 'DESCRIPTION',
  'report.result': 'RESULT',
  'report.candidateOverviewTitle': 'CANDIDATE OVERVIEW',
  'report.assessmentOverviewTitle': 'ASSESSMENT DETAILS',
  'report.turkish': 'Turkish',
  'report.english': 'English',
  'report.resultMainTitle': 'Candidate Result Report',
  'report.resultMainSubtitle': "Review the candidate's result report",
  'report.performanceTitle':
    'Candidate Performance by Topics & Learning Objectives',
  'report.performanceSubtitle':
    'Review candidate performance by topics and learning objectives',
  'report.NOT_STARTED': 'Not Started',
  'report.ONGOING': 'On Going',
  'report.COMPLETED': 'Finished',
  'report.CANCELLED': 'Canceled',
  'report.INVALID': 'Invalidated',
  'report.NOT_IMPORTED': 'Not Imported',
  'report.review': 'Review',
  'report.performanceTitleByTopics': 'Candidate Performance by Topics',
  'report.performanceTitleByLO': 'Candidate Performance by Learning Objectives',
  'report.performanceSubtitleByTopics':
    "Review candidate's exam performance by topics",
  'report.performanceSubtitleByLO':
    "Review candidate's exam performance by learning objectives",
  'report.performanceTitleByAttribute':
    'Candidate Performance by Question Attributes ({attributeName})',
  'report.performanceSubtitleByAttribute':
    "Review the candidate's exam performance against the options was associated with this question attribute.",
  'report.attributeName': 'Attribute Option Name',
  'report.attributeSubtitle': 'All options of the attribute',
  'report.attributeTitle': 'Statistics by Question Attribute Options',
  'report.radarAttributeTitle': 'Radar Chart by Question Attribute Options',

  'report.performanceTitleByCourse': 'Candidate Performance by Courses',
  'report.performanceSubtitleByCourse':
    'Review candidate performance by courses',
  'report.courseName': 'Course Name',
  'report.courseSubtitle': 'All courses',
  'report.courseTitle': 'Courses',
  'report.radarCourseTitle': 'Radar Chart by Courses',
  'report.radarCourseTitleAlert':
    'Since the number of courses is less than 3, statistical calculations can be misleading.',
  'report.numberOfCourse':
    'Since the number of courses is more than 15, it is not appropriate to create a radar chart.',
  'report.reportTotalQuestionTooltip':
    'The data in this column is calculated based only on the question attribute associated with the questions. If a question in the exam is not associated with this question attribute, it is not included in this field.',
  'report.reportWeightTooltip':
    'The data in this column is calculated based only on the question attribute associated with the questions. If a question in the exam is not associated with this question attribute, it is not included in this field.',
};

export default {
  'systemCheck.camera': 'Camera access',
  'systemCheck.mic': 'Microphone access',
  'systemCheck.shareScreen': 'Share screen',
  'systemCheck.internetSpeed': 'Internet speed (>1 mbps)',
  'systemCheck.light': 'Appropriate ambient light',
  'systemCheck.access': 'Computer device access',
  'systemCheck.requirements': 'System Requirements',
  'systemCheck.virtual': 'Virtual machine check',
  'systemCheck.devTools': 'Developer Tools',
  'systemCheck.singleScreen': 'Only one screen',
  'systemCheck.singleTab': 'Only one tab',
  'systemCheck.title': 'System Checks',
  'systemCheck.helpText': 'There is a problem. Do you want help?',
  'systemCheck.desc':
    'At this stage, we will check your system and assist you in solving problems.',
  'systemCheck.checked': 'Being checked...',
  'systemCheck.systemCheckError': 'Permission denied',
  'systemCheck.systemCheckSuccess': 'Allowed',
  'systemCheck.refresh': 'Refresh',
  'systemCheck.appropriate': 'Appropriate',
  'systemCheck.notAppropriate': 'Not Appropriate',
  'systemCheck.recheck': 'Re-check',
  'systemCheck.initNavigationResetError':
    'Your exam session has been reset by the authorized person. To continue the exam, enter the exam from the "My Assessments" menu.',
  'systemCheck.extensionError':
    'The Examod Proctoring extension was not found in your web browser. Please follow the instructions in the error message on the screen. Refresh the page after following the instructions.',

  // tooltips
  'systemCheck.mic-tooltip':
    'Due to exam rules, your browser must have access to the microphone.',
  'systemCheck.camera-tooltip':
    'Due to exam rules, your browser must have access to the camera.',
  'systemCheck.internetSpeed-tooltip':
    'Due to exam rules, your internet speed must be above the specified reference in order to monitor your network traffic during the exam.',
  'systemCheck.shareScreen-tooltip':
    'Due to exam rules, your browser is required to screen share.',
  'systemCheck.singleScreen-tooltip':
    'Due to the exam rules, you must use only one screen during the exam.',
  'systemCheck.singleTab-tooltip':
    'Due to the exam rules, you must use only one tab during the exam.',
  'systemCheck.access-tooltip':
    'Due to exam rules, you must only access the exam with a computer.',
  'systemCheck.virtual-tooltip':
    'Due to the exam rules, you cannot take the exam with a virtual machine.',
};

export default {
  'assessmentApproval.AGREEMENT_APPROVAL': 'Onay Aşamaları',
  'assessmentApproval.PROCTORING_APPROVAL': 'Onay Aşamaları',
  'assessmentApproval.AGREEMENT_APPROVAL_desc':
    'Examod ile yapılacak bu sınav ile ilgili tüm yasal sınır ve sorumluluklar aşağıdaki sözleşmede belirtilmiştir.',
  'assessmentApproval.acceptTheAgrement':
    'Şartları ve koşulları kabul ediyorum. ',
  'assessmentApproval.aggrement': 'Kullanıcı Sözleşmesi',
  'assessmentApproval.inspection': 'Denetleme Kuralları',
  'assessmentApproval.PROCTORING_APPROVAL_desc':
    'Sınav oturumunuzla ilgili açıklama ve gözetleme kuralları aşağıda sunulmuştur.',
  'assessmentApproval.CANDIDATE_VERIFICATION': 'Aday doğrulama gerekli',
  'assessmentApproval.FORCE_FULL_SCREEN': 'Tam ekran zorunlu',
  'assessmentApproval.NO_NEW_TABS': 'Sadece tek sekme',
  'assessmentApproval.DISABLE_RIGHT_CLICK': 'Sağ tık yapılamaz',
  'assessmentApproval.DISABLE_DEVTOOLS': 'Geliştirici araçları kullanılamaz',
  'assessmentApproval.RECORD_VIDEO': 'Video kaydı gerekli',
  'assessmentApproval.FORCE_PC': 'Sadece bilgisayarla girilebilir',
  'assessmentApproval.DETECT_PRINT_SCREEN': 'Ekran yakalama yapılamaz',
  'assessmentApproval.DISABLE_CLIPBOARD': 'Kes, kopyala, yapıştır yapılamaz',
  'assessmentApproval.DETECT_VM': 'Sanal makine kullanılamaz',
  'assessmentApproval.RECORD_WEB_TRAFFIC': 'Ağ trafiği takibi gerekli',
  'assessmentApproval.DETECT_MULTI_SCREEN': 'Birden çok ekran kullanılamaz',
  'assessmentApproval.DISABLE_PRINTING': 'Yazdırılamaz',
  'assessmentApproval.PREVENT_RE_ENTRY': 'Yeniden giriş yapılamaz',
  'assessmentApproval.RECORD_SCREEN': 'Ekran paylaşımı gerekli',
  'assessmentApproval.assessmentDesc': 'Değerlendirme Açıklamasını İnceleyin',
  'assessmentApproval.termsToast':
    'Devam etmek için şartlar ve koşulları kabul etmelisiniz.',
  'assessmentApproval.ONLY_ONE_SCREEN': 'Yalnızca bir ekran',

  // Tooltips
  'assessmentApproval.CANDIDATE_VERIFICATION-tooltip':
    'Sınav kuralları gereği, sistemde adınıza onaylı fotoğrafınızın, sınav öncesi ve sırasında kameranız yoluyla sizden alınan görüntü ile uyumlu bir biçimde eşleşmesi gerekmektedir.',
  'assessmentApproval.FORCE_FULL_SCREEN-tooltip':
    'Sınav kuralları gereği, sınav süresince tam ekran kullanmanız gerekmektedir.',
  'assessmentApproval.NO_NEW_TABS-tooltip':
    'Sınav kuralları gereği, sınav süresince yalnızca tek sekme kullanmanız gerekmektedir.',
  'assessmentApproval.DISABLE_RIGHT_CLICK-tooltip':
    'Sınav kuralları gereği, sınav süresince farenin sağ tuşuna tıklanması devre dışı bırakılacaktır.',
  'assessmentApproval.DISABLE_DEVTOOLS-tooltip':
    'Sınav kuralları gereği, sınav süresince geliştirici araçlarının kullanımı devre dışı bırakılacaktır.',
  'assessmentApproval.RECORD_VIDEO-tooltip':
    'Sınav kuralları gereği, sınav süresince video kaydınızın alınması gerekmektedir.',
  'assessmentApproval.FORCE_PC-tooltip':
    'Sınav kuralları gereği, sınava yalnızca bilgisayar ile giriş yapmanız gerekmektedir.',
  'assessmentApproval.DETECT_PRINT_SCREEN-tooltip':
    'Sınav kuralları gereği, sınav süresince ekran yakalama devre dışı bırakılacaktır.',
  'assessmentApproval.DISABLE_CLIPBOARD-tooltip':
    'Sınav kuralları gereği, sınav süresince kes, kopyala, yapıştır kısayolları devre dışı bırakılacaktır.',
  'assessmentApproval.DETECT_VM-tooltip':
    'Sınav kuralları gereği, sınav süresince sanal makine kullanmamanız gerekmektedir.',
  'assessmentApproval.RECORD_WEB_TRAFFIC-tooltip':
    'Sınav kuralları gereği, sınav süresince ağ trafiğinizin takibi gerekmektedir.',
  'assessmentApproval.DETECT_MULTI_SCREEN-tooltip':
    'Sınav kuralları gereği, sınav süresince cihazınızda yalnızca bir ekran kullanmanız gerekmektedir.',
  'assessmentApproval.DISABLE_PRINTING-tooltip':
    'Sınav kuralları gereği, sınavı yazdırmanız mümkün olmayacaktır.',
  'assessmentApproval.PREVENT_RE_ENTRY-tooltip':
    'Sınav kuralları gereği, sınavdan çıkış yaptıktan sonra tekrar giriş yapmanız mümkün olmayacaktır.',
  'assessmentApproval.RECORD_SCREEN-tooltip':
    'Sınav kuralları gereği, sınav süresince ekran paylaşımı yapmanız gerekmektedir.',
  'assessmentApproval.ONLY_ONE_SCREEN-tooltip':
    'Sınav kuralları gereği, sınav süresince cihazınızda yalnızca bir ekran kullanmanız gerekmektedir.',
};

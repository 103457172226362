export default {
  'assessmentSetup.BROWSER_SETUP': 'Prepare Browser',
  'assessmentSetup.GET_READY': 'Exam Preparation',
  'assessmentSetup.AGREEMENT_APPROVAL': 'Approval Stages',
  'assessmentSetup.PROCTORING_APPROVAL': 'Approval Stages',
  'assessmentSetup.SYSTEM_CHECK': 'System Check',
  'assessmentSetup.ID_CHECK': 'Candidate Face Recognition',
  'assessmentSetup.AUDIO_RECORD': "Taking the Candidate's Voice Recording",
  'assessmentSetup.FINAL_PROMPT': 'Waiting Room',
  'assessmentSetup.startExam': 'Start Exam',
  'assessmentSetup.privacyInfoTitle': 'Privacy & Security',
  'assessmentSetup.privacyInfo':
    'Your privacy and personal data is our first priority.',
  'assessmentSetup.stepInfo':
    'Help us and follow the steps to secure your effort.',
  'assessmentSetup.questions': 'Questions',
  'assessmentSetup.time': 'Exam duration',
  'assessmentSetup.noDuration': 'No duration',
  'assessmentSetup.startDate': 'Start date',
  'assessmentSetup.endDate': 'End date',
  'assessmentSetup.ready': 'I am Ready to Start',
  'assessmentSetup.question': '{count} question',
  'assessmentSetup.nextStep': 'Next Step',
  'assessmentSetup.prevStep': 'Previous Step',
  'assessmentSetup.detailsDesc': 'Details & Description',
};

import menu from './general/menu';
import login from './pages/login';
import forgotPassword from './pages/forgotPassword';
import resetPassword from './pages/resetPassword';
import lockScreen from './pages/lockScreen';
import profile from './pages/profile';
import home from './pages/home';
import faq from './pages/faq';
import maintenace from './pages/maintenance';
import notAuthorized from './pages/notAuthorized';
import passwordReset from './pages/passwordReset';
import resetPasswordEmailSent from './pages/resetPasswordEmailSent';
import ticket from './pages/ticket';
import about from './pages/about';
import buttons from './general/buttons';
import internalError from './pages/internalError';
import unknownError from './pages/unknownError';
import feBasedError from './pages/feBasedError';
import notFound from './pages/notFound';
import myAssessments from './pages/myAssessments';
import invitationResult from './pages/invitationResult';
import joinToAssessment from './pages/joinToAssessment';
import prepareBrowserStep from './pages/prepareBrowserStep';
import readyToAssessment from './pages/readyToAssessment';
import assessmentApproval from './pages/assessmentApproval';
import systemCheck from './pages/systemCheck';
import assessmentLobby from './pages/assessmentLobby';
import assessment from './pages/assessment';
import assignmentStatuses from './general/assignmentStatuses';
import assessmentReport from './pages/assessmentReport';
import assessmentStatuses from './general/assessmentStatuses';
import messages from './general/messages';
import time from './general/time';
import assessmentSetup from './pages/assessmentSetup';
import assessmentSystemCheck from './pages/assessmentSystemCheck';
import assessmentPhotoCheck from './pages/assessmentPhotoCheck';
import assessmentVerification from './pages/assessmentVerification';
import assessmentVoiceCheck from './pages/assessmentVoiceCheck';
import invite from './pages/invite';
import examEntrance from './pages/examEntrance';
import notification from './general/notification';
import pageTitle from './general/pageTitle';
import session from './general/session';
import footer from './general/footer';
import dataTable from './general/dataTable';
import userStatus from './general/userStatus';
import assessmentPreview from './pages/assessmentPreview';
import assessmentDetails from './pages/assessmentDetails';
import toasts from './general/toasts';
import alertsTitle from './pages/alertsTitle';

export default {
  ...menu,
  ...login,
  ...forgotPassword,
  ...resetPassword,
  ...lockScreen,
  ...profile,
  ...home,
  ...faq,
  ...maintenace,
  ...notAuthorized,
  ...passwordReset,
  ...resetPasswordEmailSent,
  ...ticket,
  ...about,
  ...buttons,
  ...internalError,
  ...unknownError,
  ...feBasedError,
  ...notFound,
  ...myAssessments,
  ...invitationResult,
  ...joinToAssessment,
  ...prepareBrowserStep,
  ...readyToAssessment,
  ...assessmentApproval,
  ...systemCheck,
  ...assessmentLobby,
  ...assessment,
  ...assignmentStatuses,
  ...assessmentReport,
  ...assessmentStatuses,
  ...messages,
  ...time,
  ...assessmentSetup,
  ...assessmentSystemCheck,
  ...assessmentPhotoCheck,
  ...assessmentVoiceCheck,
  ...invite,
  ...examEntrance,
  ...assessmentVerification,
  ...notification,
  ...pageTitle,
  ...session,
  ...footer,
  ...dataTable,
  ...userStatus,
  ...assessmentPreview,
  ...assessmentDetails,
  ...toasts,
  ...alertsTitle,
};

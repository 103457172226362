export default {
  'profile.header': 'Profile',
  'profile.info': 'Info',
  'profile.logout': 'Log Out',
  'profile.changePassword': 'Change Password',
  'profile.email': 'E-mail',
  'profile.username': 'User Name',
  'profile.name': 'Name',
  'profile.lastname': 'Lastname',
  'profile.company': 'Company',
  'profile.language': 'Language',
  'profile.timezone': 'Time Zone (Default: {defaultTimezone})',
  'profile.oldPassword': 'Old Password',
  'profile.newPassword': 'New Password',
  'profile.confirmPassword': 'Confirm Password',
  'profile.emailHint': 'ex: hello@examod.com',
  'profile.usernameHint': 'ex: myAwesomeUserName',
  'profile.nameHint': 'ex: John',
  'profile.lastnameHint': 'ex: Snow',
  'profile.companyHint': 'ex: Acme Ltd. Sti.',
  'profile.requiredMessage': 'This field cannot be blank.',
  'profile.passwordMessage':
    'Need minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character.',
  'profile.passMatchMessage': 'Passwords must match.',
  'profile.initialPasswordAlertMessage':
    "You're using the default system password. It's highly recommanded to update your password.",
  'profile.updatePassword': '(Update password)',
  'profile.updateSuccess': 'Your profile updated successfully!',
};

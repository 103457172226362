import { useIntl } from 'react-intl';
import { SESSION_STEPS } from '../constants/examInit';
import {
  getLocalDeviceInfo,
  removeSessionInfo,
} from '../data/UserLocalDataSource';
import { navigateToAssessment, navigateToAssessmentInit } from '../Navigator';
import { toastError } from '../utility/toastUtil';
import { COLOR_NAMES } from '../configs/Style';

export const AssignmentStatuses = {
  ONGOING: 'ONGOING',
  OVERDUE: 'OVERDUE',
  COMPLETED: 'COMPLETED',
  NOT_ATTENDED: 'NOT_ATTENDED',
  INVALIDATED: 'INVALIDATED',
  PASSIVE: 'PASSIVE',
  NOT_STARTED: 'NOT_STARTED',
  CANCELLED: 'CANCELLED',
};

export const PerformanceStatuses = {
  EVALUATED: 'EVALUATED',
  NOT_EVALUATED: 'NOT_EVALUATED',
  NOT_ATTENDED: 'NOT_ATTENDED',
};

export const AssignmentTypes = {
  ON_SITE: 'ON_SITE',
  PAPER_BASED: 'PAPER_BASED',
  PAPER_BASED_CLASSIC: 'PAPER_BASED_CLASSIC',
  PERFORMANCE_EXAM: 'PERFORMANCE_EXAM',
  ONLINE: 'ONLINE',
};

export const gradingTypes = {
  NONE: 'NONE',
  ABSOLUTE: 'ABSOLUTE',
  GLOBAL_RATING: 'GLOBAL_RATING',
  BELL_CURVE: 'BELL_CURVE',
};

export const assessmentMethodTypes = {
  MANUAL: 'MANUAL',
  RUBRIC: 'RUBRIC',
};

export const verificationLevels = {
  NONE: 'NONE',
  VERIFY_ID: 'VERIFY_ID',
};

export const verificationStatus = {
  CANDIDATE_APPROVED: 'CANDIDATE_APPROVED',
  FACE_NOT_VISIBLE: 'FACE_NOT_VISIBLE',
  NOT_RECOGNIZED: 'NOT_RECOGNIZED',
  WAITING_CAMERA: 'WAITING_CAMERA',
  PENDING_PROOF_VIDEO: 'PENDING_PROOF_VIDEO',
  PENDING_PROCESSING: 'PROCESSING',
  NOT_RECORDED: 'NOT_RECORDED',
  NOT_STARTED: 'NOT_STARTED',
};

export const browserType = {
  chrome: 'CHROME_GENERIC',
  firefox: 'FIREFOX_GENERIC',
  safari: 'SAFARI_GENERIC',
  edge: 'EDGE_GENERIC',
  internet_explorer: 'IE_GENERIC',
  ie: 'IE_GENERIC',
  unknown: 'UNKNOWN',
};

export const operatingSystems = {
  WINDOWS: 'WINDOWS',
  MAC_OS: 'MAC_OS',
  LINUX: 'LINUX',
  UNKNOWN: 'UNKNOWN',
};

export const assessmentStatuses = {
  FINISHED: 'FINISHED',
  ON_GOING: 'ONGOING',
  NOT_STARTED: 'NOT_STARTED',
  CANCELLED: 'CANCELLED',
  IN_RESET: 'IN_RESET',
};

export const candidateAssessmentStatuses = {
  INVALID: 'INVALID',
  COMPLETED: 'COMPLETED',
};
export const faceRecognitionSteps = {
  INIT_STEP: 'initStep',
  PROCESSING_STEP: 'processingStep',
  REJECT_RESULT_STEP: 'rejectResultStep',
  APPROVE_RESULT_STEP: 'approveResultStep',
  VIDEO_RECORD_STEP: 'videoRecordStep',
  VIDEO_PLAYER_STEP: 'videoPlayerStep',
  VIDEO_NOT_RECORDED_STEP: 'videoNotRecordedStep',
  SUCCESSFUL_RECOGNITION: 'successfulRecognition',
};

export const generalInfoLabels = [
  'unit',
  'assessmentCategory',
  'resultType',
  'timeOver',
];

export const detailLabels = [
  { label: 'highestGrade', badgeColor: COLOR_NAMES.GREEN },
  { label: 'correctionGuessing', badgeColor: COLOR_NAMES.YELLOW },
  { label: 'gradingMethod', badgeColor: COLOR_NAMES.ORANGE },
  { label: 'passPoint', badgeColor: COLOR_NAMES.PURPLE },
  { label: 'process', badgeColor: COLOR_NAMES.NAVY_BLUE },
  { label: 'proctoringType', badgeColor: COLOR_NAMES.EMERALD },
];

export const assessmentProcessOptions = {
  shuffleQuestions: 'shuffleQuestions',
  shuffleOptions: 'shuffleOptions',
  min5: '5min',
  navigation: 'navigation',
  elimination: 'elimination',
};

/*
 * Question operation options
 */
export const questionOverruleOptions = {
  CORRECT: 'CORRECT',
  REMOVE: 'REMOVE',
  NONE: 'NONE',
};

export const candidateReportTables = {
  topic: 'topic',
  obj: 'obj',
  attribute: 'attribute',
  course: 'course',
};

export const videRecordingLength = 10000;
export const getOperatingSystem = (type) => {
  const OS = type.toUpperCase().replace(' ', '_');
  if (operatingSystems[OS]) {
    return operatingSystems[OS];
  } else {
    return operatingSystems.UNKNOWN;
  }
};

export const getBrowserType = (type) => {
  const browser = type.toLowerCase().replace(' ', '_');
  if (browserType[browser]) {
    return browserType[browser];
  } else {
    return browserType.unknown;
  }
};

export const GetAssignmentStatusLocalisedText = (assignmentStatus) => {
  let intl = useIntl();
  let assignmentStatusLocalisedText = intl.formatMessage({
    id: 'assignmentStatus.' + assignmentStatus.toLowerCase(),
  });

  return assignmentStatusLocalisedText;
};

export const GetAssessmentTypeLocalisedText = (assessmentType) => {
  let intl = useIntl();
  let assignmentStatusLocalisedText = intl.formatMessage({
    id: 'assessmentStatus.' + assessmentType,
  });

  return assignmentStatusLocalisedText;
};

export const statusResultVerifyId = {
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const PERMISSION_DENIED_SYSTEM = 'Permission denied by system';

export const deleteSessionInfo = (dispatch) => {
  removeSessionInfo();
  dispatch({ sessionInfo: null });
};

export const checkAndJoinActiveSession = (sessionInfo, dispatch, intl) => {
  if (sessionInfo?.deviceId === getLocalDeviceInfo()?.deviceId) {
    if (sessionInfo.currentStep !== SESSION_STEPS.ONGOING) {
      navigateToAssessmentInit(sessionInfo?.assessment?.id, sessionInfo?.id);
      deleteSessionInfo(dispatch);
    } else {
      navigateToAssessment(sessionInfo?.assessment?.id, sessionInfo?.id);
    }
  } else {
    toastError(intl.formatMessage({ id: 'session.deviceError' }));
  }
};

export const EVENT_END = 'END';
export const EVENT_START = 'START';

export const EVENT_VIOLATIONS = {
  SCREEN_SHARING_OFF: { name: 'SCREEN_SHARING_OFF', requiresExtension: true },
  FULL_SCREEN_OFF: { name: 'FULL_SCREEN_OFF', requiresExtension: false },
  CAMERA_NOT_FOUND: { name: 'CAMERA_NOT_FOUND', requiresExtension: true },
  MIC_NOT_FOUND: { name: 'MIC_NOT_FOUND', requiresExtension: true },
};

export const isQuestionOverruled = (statOrOverruleType) => {
  let overruleType;
  if (statOrOverruleType?.overruleType) {
    overruleType = statOrOverruleType.overruleType;
  } else {
    overruleType = statOrOverruleType;
  }
  return (
    overruleType === questionOverruleOptions.CORRECT ||
    overruleType === questionOverruleOptions.REMOVE
  );
};

export const isPerformance = (assessmentType) => {
  return assessmentType === AssignmentTypes.PERFORMANCE_EXAM;
};

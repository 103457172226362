export default {
  'assessment.next': 'Next Question',
  'assessment.prev': 'Previous Question',
  'assessment.finish': 'Finish Exam',
  'assessment.description': 'Assessment Description',
  'assessment.examDesc': 'Assessment Description',
  'assessment.finishConfirmationTitle': 'Finish Exam',
  'assessment.finishConfirmationInfoEmpty':
    'There are {empty} empty questions.',
  'assessment.finishConfirmationInfoFlagged':
    'There are {flagged} flagged questions.',
  'assessment.finishConfirmationInfoBoth':
    'There are {empty} empty and {flagged} flagged questions.',
  'assessment.finishConfirmationMessage': 'Are you sure to finish your exam?',
  'assessment.finishConfirmationButton': 'Yes, finish exam',
  'assessment.question': 'Question #{number}',
  'assessment.groupedQuestion':
    'Answer questions {first} - {last} according to the question content below.',
  'assessment.groupSingle':
    'Question #{number} - Choose the <u>true</u> option.',
  'assessment.choose': 'Choose the <u>true</u> option.',
  'assessment.panel': 'Exam Panel',
  'assessment.flagged': 'Flagged',
  'assessment.unflagged': 'Unflagged',
  'assessment.session': 'Session ID: ',
  'assessment.total': 'Time Remaining: ',
  'assessment.section': 'Section: ',
  'assessment.timeWarning': 'Only 5 minutes left to complete your exam!',
  'assessment.time': '5 minutes',
  'assessment.warning': 'Warning!',
  'assessment.fullScreenWarning':
    'Due to exam rules;\nWe have to make your internet browser full screen.\nClick anywhere on the screen to view the exam.',
  'assessment.focusWarning':
    'You have exhibited a suspicious behavior outside of the exam rules. All these suspicious behaviors are recorded.',
  'assessment.focusWarningButton': 'Yes, I understand.',
  'assessment.AUTHORIZED': 'Authorized',
  'assessment.CANDIDATE': 'Candidate',
  'assessment.screenshotWarning':
    'Due to exam rules;\nScreen capture cannot be performed in this exam.\nClose the screen capture tool to continue the exam.',
  'assessment.dueToExamRules': 'Due to exam rules;',
  'assessment.pcForceWarning':
    'This exam can only be accessed by computer.You can’t use mobile device for this exam!',
  'assessment.screenShareForceWarning':
    'Due to exam rules;\nWhole screen sharing is required in this exam.\nDo not turn the screen share off for this exam!',
  'assessment.attachments': `Attachments`,
  'assessment.attachmentWarn':
    'This question has {count} attachments containing video/audio files',
  'assessment.cameraPermWarn1': 'Video recording is required for this exam.',
  'assessment.cameraPermWarn2':
    'Turn on or connect your camera again to continue the exam.',
  'assessment.micPermWarn1': 'Audio recording is required for this exam.',
  'assessment.micPermWarn2':
    'Turn on or connect your microphone again to continue the exam.',
  'assessment.details': 'Details',

  'assessment.understand': 'I understand',
  'assessment.help': 'Help',
  'assessment.RECORD_MIC_title': 'Microphone Not Found',
  'assessment.RECORD_SCREEN_title': 'Screen Sharing Disabled',
  'assessment.RECORD_VIDEO_title': 'Camera Not Found',
  'assessment.SCREEN_CAPTURE_title': 'Screen Capture Detected',
  'assessment.FULL_SCREEN_title': 'Exited Full Screen',
  'assessment.FORCE_PC_title': 'Device Not Available',
  'assessment.ONLY_ONE_SCREEN_title': 'Multiple Screen Detected',
  'assessment.NO_NEW_TABS_title': 'Multiple Tab Detected',
  'assessment.EXTENSION_DISABLED_title': 'Examod Proctoring Extension Disabled',
  'assessment.PROCTORING_EXTENSION_title': 'Examod Proctoring Extension',
  'assessment.RECORD_MIC_exp1':
    'Due to exam rules, audio recording is required.',
  'assessment.RECORD_MIC_exp2':
    'Turn on or connect your microphone again to continue the exam.',
  'assessment.RECORD_VIDEO_exp1':
    'Due to exam rules, video recording is required.',
  'assessment.RECORD_VIDEO_exp2':
    'Turn on or connect your camera again to continue the exam.',
  'assessment.RECORD_SCREEN_exp1':
    'Due to exam rules, screen sharing is required.',
  'assessment.RECORD_SCREEN_exp2':
    'Do not turn the screen share off for this exam!',
  'assessment.FORCE_PC_exp1':
    'Due to exam rules, this exam can only be accessed by computer.',
  'assessment.FORCE_PC_exp2': 'You can not use mobile device for this exam.',
  'assessment.FULL_SCREEN_exp1':
    'Due to exam rules, we have to make your internet browser full screen.',
  'assessment.FULL_SCREEN_exp2':
    'Click anywhere on the screen to view the exam.',
  'assessment.SCREEN_CAPTURE_exp1':
    'Due to exam rules, screen capture cannot be performed.',
  'assessment.SCREEN_CAPTURE_exp2':
    'Close the screen capture tool to continue the exam.',
  'assessment.RECORD_MIC_subtitle':
    "We couldn't find your microphone. Please try the solutions below.",
  'assessment.RECORD_MIC_hint_exp1':
    'Click the {icon} icon in your browser’s adress bar and turn on Microphone.',
  'assessment.RECORD_MIC_hint_exp2':
    'Check your system. Make sure the microphone is available and active',
  'assessment.RECORD_MIC_hint_exp3': 'Refresh the page.',
  'assessment.RECORD_VIDEO_subtitle':
    "We couldn't find your camera. Please try the solutions below",
  'assessment.PROCTORING_EXTENSION_subtitle1':
    'This exam requires the <b>Examod Proctoring</b> extension.{br}Please check the Examod Proctoring extension and refresh the page.',
  'assessment.PROCTORING_EXTENSION_subtitle2':
    'If the extension is not installed',
  'assessment.PROCTORING_EXTENSION_button': 'Add to Chrome',
  'assessment.RECORD_VIDEO_hint_exp1':
    'Click the  {icon} icon in your browser’s adress bar and turn on Camera.',
  'assessment.RECORD_VIDEO_hint_exp2':
    'Check your system. Make sure the camera is available and active.',
  'assessment.RECORD_VIDEO_hint_exp3': 'Refresh the page.',
  'assessment.PROCTORING_EXTENSION_hint_exp1':
    'Make sure the <b>Examod Proctoring</b> extension is installed and updated.',
  'assessment.PROCTORING_EXTENSION_hint_exp2':
    'Click the {icon} icon in your <b>Google Chrome</b> web browser’s address bar and turn on <b>Examod Proctoring</b> extension.',
  'assessment.PROCTORING_EXTENSION_hint_exp3':
    'Install the extension by clicking the <b>"Add to Chrome"</b> button.',
  'assessment.PROCTORING_EXTENSION_hint_exp4':
    '<b>Refresh</b> your page after the extension installation is complete.',
  'assessment.ONLY_ONE_SCREEN_subtitle':
    'We have detected multiple screens. Please try the solutions below.',
  'assessment.ONLY_ONE_SCREEN_hint_exp1':
    'Make sure you only have one screen connected to your device.',
  'assessment.ONLY_ONE_SCREEN_hint_exp2':
    'Disable screen mirroring or stretching features.',
  'assessment.ONLY_ONE_SCREEN_hint_exp3': 'Refresh the page.',
  'assessment.NO_NEW_TABS_subtitle':
    'We detected multiple tabs. Please try the solutions below.',
  'assessment.NO_NEW_TABS_hint_exp1':
    'Make sure that only the exam session tab is open in your browser.',
  'assessment.NO_NEW_TABS_hint_exp2':
    'Close all tabs except the exam session tab.',
  'assessment.NO_NEW_TABS_hint_exp3': 'Refresh the page.',
  'assessment.EXTENSION_DISABLED_exp1':
    'Due to exam rules, the Examod Proctoring extension must be active and updated.',
  'assessment.EXTENSION_DISABLED_exp2':
    'Check Examod Proctoring extension and refresh the page.',
  'assessment.shareScreen': 'Share My Screen',
  'assessment.resetToast':
    'Your exam session has been reset by the authorized.',
  'assessment.titleInformation':
    'Whoops! There is no <span>assessment</span> yet.',
  'assessment.messageInformation':
    'You do not have an assessment yet. When you are added to an assessment, details will be displayed here.',
};

export default {
  'assessmentApproval.AGREEMENT_APPROVAL': 'Approval Stages',
  'assessmentApproval.PROCTORING_APPROVAL': 'Approval Stages',
  'assessmentApproval.AGREEMENT_APPROVAL_desc':
    'All legal limits and responsibilities regarding this exam to be conducted with Examod are mentioned in the agreement below.',
  'assessmentApproval.acceptTheAgrement': 'I accept the terms and conditions.',
  'assessmentApproval.aggrement': 'User Agreement',
  'assessmentApproval.inspection': 'Proctoring Rules',
  'assessmentApproval.PROCTORING_APPROVAL_desc':
    'Assessment description and proctoring rules for your exam session are presented below.',
  'assessmentApproval.CANDIDATE_VERIFICATION':
    'Candidate verification required',
  'assessmentApproval.FORCE_FULL_SCREEN': 'Full screen required',
  'assessmentApproval.NO_NEW_TABS': 'Only one tab',
  'assessmentApproval.DISABLE_RIGHT_CLICK': 'Right click disabled',
  'assessmentApproval.DISABLE_DEVTOOLS': 'Developer tools are disabled',
  'assessmentApproval.RECORD_VIDEO': 'Video recording required',
  'assessmentApproval.FORCE_PC': 'Accessible by computer only',
  'assessmentApproval.DETECT_PRINT_SCREEN': 'Screen capture disabled',
  'assessmentApproval.DISABLE_CLIPBOARD': 'Cut, copy, paste disabled',
  'assessmentApproval.DETECT_VM': 'Virtual machine are forbidden',
  'assessmentApproval.RECORD_WEB_TRAFFIC': 'Network traffic tracking required',
  'assessmentApproval.DETECT_MULTI_SCREEN': 'Multi screens are disabled',
  'assessmentApproval.DISABLE_PRINTING': 'Unprintable',
  'assessmentApproval.PREVENT_RE_ENTRY': 'Re-login disabled',
  'assessmentApproval.RECORD_SCREEN': 'Screen sharing required',
  'assessmentApproval.assessmentDesc': 'Review Assessment Description',
  'assessmentApproval.termsToast':
    'You must accept the terms and conditions to continue.',
  'assessmentApproval.ONLY_ONE_SCREEN': 'Only one screen',

  // Tooltips
  'assessmentApproval.CANDIDATE_VERIFICATION-tooltip':
    'Due to exam rules, your photo approved in the system must match the image taken from you via your camera before and during the exam.',
  'assessmentApproval.FORCE_FULL_SCREEN-tooltip':
    'Due to exam rules, you must use the full screen during the exam.',
  'assessmentApproval.NO_NEW_TABS-tooltip':
    'Due to the exam rules, you must use only one tab during the exam.',
  'assessmentApproval.DISABLE_RIGHT_CLICK-tooltip':
    'Due to exam rules, right click of the mouse will be disabled during the exam.',
  'assessmentApproval.DISABLE_DEVTOOLS-tooltip':
    'Due to exam rules, the use of developer tools will be disabled during the exam.',
  'assessmentApproval.RECORD_VIDEO-tooltip':
    'Due to exam rules, your video recording must be taken during the exam.',
  'assessmentApproval.FORCE_PC-tooltip':
    'Due to exam rules, you must only access the exam with a computer.',
  'assessmentApproval.DETECT_PRINT_SCREEN-tooltip':
    'Due to the exam rules, screen capture will be disabled during the exam.',
  'assessmentApproval.DISABLE_CLIPBOARD-tooltip':
    'Due to exam rules, cut, copy, paste shortcuts will be disabled during the exam.',
  'assessmentApproval.DETECT_VM-tooltip':
    'Due to exam rules, you must not use a virtual machine during the exam.',
  'assessmentApproval.RECORD_WEB_TRAFFIC-tooltip':
    'Due to exam rules, your network traffic must be tracked during the exam.',
  'assessmentApproval.DETECT_MULTI_SCREEN-tooltip':
    'Due to exam rules, you must use only one screen on your device during the exam.',
  'assessmentApproval.DISABLE_PRINTING-tooltip':
    'Due to exam rules, you will not be able to print the exam.',
  'assessmentApproval.PREVENT_RE_ENTRY-tooltip':
    'Due to exam rules, you will not be able to re-login after exiting the exam.',
  'assessmentApproval.RECORD_SCREEN-tooltip':
    'Due to exam rules, you must share the screen during the exam.',
  'assessmentApproval.ONLY_ONE_SCREEN-tooltip':
    'Due to exam rules, you must use only one screen on your device during the exam.',
};

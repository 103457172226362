import React from "react"
import Avatar from '../../@vuexy/avatar/AvatarComponent'

const UserAvatar = ({ name, size, color, showStatus }) => {

    const colors = ["primary", "success", "danger", "info", "warning", "dark"]

    return (
        showStatus ?
            <Avatar
                color={colors[color]}
                size={size}
                icon={name}
                status="online" 
            />
            :
            size ?
                <Avatar
                    color={colors[color]}
                    size={size}
                    icon={name}
                />
                :
                <Avatar
                    className="custom-avatar"
                    color={colors[color]}
                    icon={name}
                />

    )
}

export default UserAvatar
import React from 'react';
import themeConfig from '../configs/themeConfig';
import classnames from 'classnames';

const ExamLayout = ({ children, ...rest }) => {
  return (
    <div
      className={classnames(
        'exam-layout full-layout wrapper bg-solid blank-page dark-layout',
        {
          'layout-dark': themeConfig.layoutDark,
        }
      )}
    >
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="flexbox-container">
              <main className="main  exam-main-content w-100 ">{children}</main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamLayout;

export default {
  'notification.ASSESSMENT_COMPLETED': '{examName} sınavı sonlanmıştır.',
  'notification.ASSESSMENT_RESULT_ANNOUNCED':
    '{examName} sınavınızın sonuçları ilan edildi.',
  'notification.ASSESSMENT_ASSIGNED': '{examName} sınavına eklendiniz.',
  'notification.ASSESSMENT_UPDATED':
    '{examName} sınavınızda bazı değişiklikler yapıldı. ',
  'notification.FACE_ID_VERIFICATION':
    'Yüz kimliğiniz onaylandı. Artık yüz tanıma gerektiren sınavlara girebilirsiniz.',
  'notification.exam': 'Sınav Bildirimi',
  'notification.account': 'Hesap Bildirimi',
  'notification.count': '{count} Yeni',
  'notification.notifications': 'Bildirimler',
  'notification.clear': 'Tümünü Temizle',
  'notification.noNotifications': 'Henüz bildirim yok',
};

import momentTz from 'moment-timezone';
import { useIntl } from 'react-intl';

// ** Default date format used in the app
export const DEFAULT_DATE_FORMAT = 'd/m/Y';

// ** Default date format used in the calendar
export const CALENDAR_DATE_FORMAT = 'DD/MM/YYYY';

// ** Default date time format
export const DEFAULT_DATETIME_FORMAT = 'd/m/Y H:i';

export const BOOKLET_DATE_FORMAT = 'DD MMMM YYYY';
export const BOOKLET_TIME_FORMAT = 'HH:mm';

// ** Default Duration Time format
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
export const UPCOMING_EXAM_DATE_FORMAT = 'DD.MM.YYYY, HH:mm';
export const REPORT_DATE_FORMAT = 'DD/MM/YYYY HH:mm';
export const ASSESSMENT_DATE_FORMAT = 'DD/MM/YYYY - HH:mm';

export const setDefaultTimezone = (timezone) => {
  momentTz.tz.setDefault(timezone);
};

// ** Gets the date format as calender date format
export const getCalendarFormat = (date) => {
  if (date) {
    var newFormat = momentTz(date).format(CALENDAR_DATE_FORMAT);
    return newFormat;
  }
  return null;
};

// ** Gets the date format as booklet cover date format
export const getBookletDateFormat = (date) => {
  return momentTz(date).format(BOOKLET_DATE_FORMAT);
};

// ** Gets the time format as booklet cover date format
export const getBookletTimeFormat = (date) => {
  return momentTz(date).format(BOOKLET_TIME_FORMAT);
};

// ** Gets the date format as dashboard upcoming exam date format
export const getUpcomingExamDateFormat = (date) => {
  return momentTz(date).format(UPCOMING_EXAM_DATE_FORMAT);
};

// ** Gets the date format as report exam date format
export const getReportDateFormat = (date) => {
  return momentTz(date).format(REPORT_DATE_FORMAT);
};

// Gets the date format as booklet cover date format
export const getAssessmentDateFormat = (date) => {
  return momentTz(date).format(ASSESSMENT_DATE_FORMAT);
};

export const getDateFormat = (date, seperatorString) => {
  if (date) {
    let seperator = seperatorString;
    if (!seperator) {
      seperator = ' ';
    }
    return getBookletDateFormat(date) + seperator + getBookletTimeFormat(date);
  }
  return '-';
};
export const getDateFormatDefault = (date) => {
  return getCalendarFormat(date) + ' ' + getBookletTimeFormat(date);
};

// ** Gets the time format text
export const GetTimeFormatText = (sec) => {
  const intl = useIntl();
  if (!sec) {
    return 0;
  }
  let time = momentTz().startOf('day').seconds(sec).format(DEFAULT_TIME_FORMAT);
  let timeArray = time.split(':');
  timeArray = timeArray.map((item) => parseInt(item));
  if (timeArray[0] !== 0) {
    timeArray[0] = intl.formatMessage(
      { id: 'time.hour' },
      { hour: timeArray[0] }
    );
  }
  if (timeArray[1] !== 0) {
    timeArray[1] = intl.formatMessage(
      { id: 'time.min' },
      { min: timeArray[1] }
    );
  }
  if (timeArray[2] !== 0) {
    timeArray[2] = intl.formatMessage(
      { id: 'time.sec' },
      { sec: timeArray[2] }
    );
  }
  return timeArray.filter((e) => e != 0).join(' ');
};

export const GetTimeClockFormatText = (sec) => {
  if (!sec) {
    return '-';
  }
  let time = momentTz().startOf('day').seconds(sec).format(DEFAULT_TIME_FORMAT);
  return time;
};

export const getTimezoneAbbreviations = (zoneName, shortForm) => {
  let offsetMinutes;
  if (!zoneName) {
    offsetMinutes = momentTz().utcOffset();
  } else {
    offsetMinutes = momentTz.tz(zoneName).utcOffset(); // Get the UTC offset in minutes for the specified timezone
  }

  // Convert the offset to hours and minutes
  const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
  const offsetMinutesRemainder = Math.abs(offsetMinutes % 60);
  const offsetSign = offsetMinutes >= 0 ? '+' : '-';
  const formattedOffset = `${offsetSign}${String(offsetHours).padStart(
    2,
    '0'
  )}:${String(offsetMinutesRemainder).padStart(2, '0')}`;
  if (shortForm) {
    return (
      'UTC ' +
      offsetSign +
      offsetHours +
      (offsetMinutesRemainder ? ':' + offsetMinutesRemainder : '')
    );
  }
  return `UTC${formattedOffset}`;
};

export const getFullTimezone = (timezone) => {
  return `(${getTimezoneAbbreviations(timezone, true)}) ${timezone}`;
};

export const getYear = () => {
  return new Date().getFullYear();
};

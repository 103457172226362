export default {
  'myAssessments.header': 'Sınavlarım',
  'myAssessments.information': 'Size tanımlanmış tüm sınavların listesi',
  'myAssessments.name': 'Değerlendirme Adı',
  'myAssessments.status': 'Durum',
  'myAssessments.actions': 'İşlemler',
  'myAssessments.searchFilter': 'Arama Filtresi',
  'myAssessments.search': 'Arama',
  'myAssessments.selectStatus': 'Seçiniz',
  'myAssessments.selectAssessmentType': 'Değerlendirme Tipi Seçin',
  'myAssessments.selectAssessmentCategory': 'Kategori Seçin',
  'myAssessments.examEntrance': 'Sınav Girişi',
  'myAssessments.examInformation': 'Sınav Bilgileri',
  'myAssessments.assessmentType': 'Değerlendirme Türü',
  'myAssessments.report': 'Sınav Raporu',
  'myAssessments.type': 'Tür',
  'myAssessments.category': 'KATEGORİ',
  'myAssessments.assessmentCategory': 'Değerlendirme Kategorisi',
  'myAssessments.duration': 'Süre',
  'myAssessments.dates': 'Başlangıç & Bitiş Zamanı',
  'myAssessments.unit': 'Birim / Ders',
  'myAssessments.switchText': 'Süresi geçen ve geçersiz sınavları göster',
  'myAssessments.reviewResponse': 'Soru ve Yanıtları İncele',
};

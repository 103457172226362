export const USER_LOCAL_DATA = {
  token: 'token',
  rememberToken: 'rememberToken',
  lang: 'lang',
  deviceInfo: 'device-info',
  deviceId: 'device-id',
  examInitiated: 'exam-initiated',
  flaggedQuestion: 'flagged-questions',
  sessionInfo: 'session-info',
  navbarState: 'navbarState',
  eventQueue: 'eventQueue',
  eliminatedAnswer: 'eliminated-answers',
};

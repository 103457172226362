export default {
  'prepareBrowserStep.prepareBrowser': 'Tarayıcıyı Hazırlama',
  'prepareBrowserStep.desc':
    'Gireceğiniz sınavın kurallarına göre bazı ön hazırlıklar yapmanız gerekmektedir.',
  'prepareBrowserStep.extension': 'Examod Proctoring Eklentisi',
  'prepareBrowserStep.checked': 'Kontrol ediliyor...',
  'prepareBrowserStep.browserCheckError': 'Bulunamadı.',
  'prepareBrowserStep.browserCheckSuccess': 'Onaylandı',
  'prepareBrowserStep.dowloadLink': 'Yükle',
  'prepareBrowserStep.addToChrome': "Chrome'a Ekle",
  'prepareBrowserStep.examRequirementChromeAlert':
    'Bu sınav <b>Google Chrome</b> internet tarayıcısı gerektirmektedir.',
  'prepareBrowserStep.examRequirementProctoringAlert':
    'Bu sınav <b>Google Chrome</b> internet tarayıcısı ve <b>Examod Proctoring Eklentisi</b> eklentisi gerektirmektedir.',
  'prepareBrowserStep.extensionRequired':
    'Bu sınav <b>Examod Proctoring Eklentisi</b> uygulamasını gerektirmektedir.',
  'prepareBrowserStep.extensionTooltip':
    'Examod Proctoring eklentisi sınav süresince ekran paylaşımınız ve web tarayıcınızda çeşitli kontroller sağlar. Eklenti, aday gizliliğine ve veri güvenliğine önem vererek hile yapılmasını önler. Bu sınavda Examod Proctoring eklentisinin sınav süresince aktif olması zorunludur.',
  'prepareBrowserStep.extensionNotFoundTitle':
    '<b>Examod Proctoring</b> eklentisi bulunamadı. Lütfen aşağıdaki yönergeleri izleyin ve sayfayı <b>yenileyin</b>.',
  'prepareBrowserStep.extensionNotFoundInfo1':
    "<b>Examod Proctoring</b> eklentisinin yüklü ve güncel olduğundan emin olun. <b><u>(Chrome'a Ekle)</u></b>",
  'prepareBrowserStep.extensionNotFoundInfo2':
    '<b>Examod Proctoring</b> eklentisinin aktif olduğundan emin olun.',
  'prepareBrowserStep.extensionNotFoundInfo3':
    '<b>Examod Proctoring</b> eklentisini kaldırın ve yeniden yükleyin.',
  'prepareBrowserStep.extensionNotFoundInfo4':
    'Ve sayfayı <b><u>YENİLEYİN.</u></b>',
};

import { toastError } from "./toastUtil"

/*
 * Check the result in the view
 */
export const checkResult = (result, alert = true) => {
    if (result && result != null) {
        if (result.success == false && alert) {
            toastError(result.apiError.errorMessage)
        }
        return result.success
    }

    return false
}

export default {
  'assessmentVerification.title': ' Candidate Face Recognition',
  'assessmentVerification.desc':
    'This photo before and during the exam, will be compared with your approved photo in the system.',
  'assessmentVerification.cameraView': 'Your Camera View',
  'assessmentVerification.RecognitionResult': 'Face Recognition Result',
  'assessmentVerification.instructionFacePlace':
    'Place your face in the mold on the camera view.',
  'assessmentVerification.autoCaptureInfo':
    'Your front view photo will be captured automatically by the Examod.',
  'assessmentVerification.instructionViewCapture':
    'This photo <b>before</b> and <b>during</b> the exam, will be compared with your approved photo in the system.',
  'assessmentVerification.agreementSwitchLabel':
    'I would like to continue the exam, provided that my photo and video captured by the Examod is reviewed later. I accept<span>all responsibility</span>in case of a candidate mismatch.',
  'assessmentVerification.candidateApproved': 'Candidate approved',
  'assessmentVerification.waitingCamera': 'Waiting Camera',
  'assessmentVerification.processing': 'Processing',
  'assessmentVerification.faceNotVisible': 'Face not visible',
  'assessmentVerification.notRecognized': 'Not recognized',

  'assessmentVerification.yourCameraView': 'Your Camera View',
  'assessmentVerification.clickToVerifyDescription': `Click the {Verify} button when you are ready to perform your face recognition.`,
  'assessmentVerification.verify': 'Verify',
  'assessmentVerification.verifyQuote': '"Verify"',

  'assessmentVerification.cameraCardTitle': 'Your Camera View',

  'assessmentVerification.yourFaceNotIdentified': `Your face identity has not been verified. Before starting the exam, please take a video recording by following the instructions below.`,
  'assessmentVerification.yourFaceNotIdentifiedToast': `Your face identity has not been verified. Before starting the exam, please take a video recording by following the instructions.`,

  'assessmentVerification.Record': 'Record',
  'assessmentVerification.reRecord': 'Re-record',
  'assessmentVerification.whenYouAreReady': `When you are ready, press the {Record} button and <b>repeat the following sentence</b>. Video recording will automatically take 10 seconds.`,
  'assessmentVerification.amReadyForExam': `Hi, I'm {name}  {surname}. I'm ready for the exam.`,
  'assessmentVerification.Recording': 'Recording',
  'assessmentVerification.yourVideoNotRecorded':
    'Your video has not been recorded.',
  'assessmentVerification.checkCamera':
    'Please check your camera settings and try again.',

  'assessmentVerification.record_text': '"Record"',

  'assessmentVerification.notStarted': 'Not Started',
  'assessmentVerification.recorded': 'Recorded',
  'assessmentVerification.notRecorded': 'Not Recorded',

  'assessmentVerification.yourFaceSuccessfullyIdentified': `Your face identity has been successfully verified.Before starting the exam, please take a video recordingby following the instructions below.`,
  'assessmentVerification.errorCameraPermission':
    'To complete before exam face recognition process, you must allow your browser camera/microphone access.',

  'assessmentVerification.refreshThePage':
    'You can reload the page if there is a problem with the face recognition step.',
};

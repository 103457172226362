export default {
  'invite.password': 'Şifre',
  'invite.confirmPassword': 'Şifre Doğrulama',
  'invite.requiredError': 'Bu alan boş bırakılamaz.',
  'invite.invitationTitle': 'Davet',
  'invite.invitationSubtitle': "Examod'a Kayıt ol",
  'invite.personalTitle': 'Kişisel',
  'invite.personalSubtitle': 'Hesap Bilgileri',
  'invite.exploreTitle': 'Keşfet',
  'invite.exploreSubtitle': "Examod'u Keşfet",
  'invite.userGreetings':
    "Sayın, <red>{userMail}</red>{br}<h1>{organizationName}</h1>kuruluşunun <b>Examod: Dijital Değerlendirme Yazılımı'na davet edildiniz!</b>",
  'invite.signUp': "Examod'a Kayıt ol",
  'invite.haveAccount': 'Zaten bir hesabın var mı? <b>Giriş yap </b>',
  'invite.accountInformation':
    '{organizationName} kuruluşunun <b>Examod</b> yazılımına katılmak için hesap bilgilerinizi tamamlayın.',
  'invite.email': 'E-Posta',
  'invite.userType': 'Kullanıcı Tipi',
  'invite.firstName': 'Ad',
  'invite.lastName': 'Soyad',
  'invite.createAccount': 'Hesap Oluştur',
  'invite.minLengthError': 'Şifre en az 6 karakterden oluşmalıdır',
  'invite.patternError':
    'Şifre en az bir büyük harf, bir küçük harf, bir sayı ve bir özel karakter içermelidir',
  'invite.validateError': 'Şifreler aynı olmalıdır',
  'invite.welcomeUser':
    'Sayın, <red>{userName}</red>{br}<h1>Kayıt olduğunuz için teşekkürler!</h1><b>{organizationName}</b> kuruluşunun <b>Examod</b> yazılımına hoş geldiniz!',
  'invite.goDashboard': 'Kullanıcı Paneline Git',
  'invite.discard': 'Değişiklikleri Gözardı Et',
  'invite.discardInfo':
    'Kayıt işleminden çıkmak istediğinizden emin misiniz? Kayıt işlemini iptal ederseniz, tüm bilgileriniz silinecektir.',
  'invite.cancel': 'İptal',
  'invite.continue': 'Devam et',
  'invite.fewClickAway': "Examod'a katılmaktan birkaç tık uzaktasınız",
  'invite.oneClickAway':
    "Başarılı! Artık Examod'u keşfetmekten bir tık uzaktasınız",
  'invite.invalidTokenError': 'Geçersiz bağlantı',
  'invite.tokenExpiredError': 'Bağlantı süresi doldu',
};

import React from 'react';
/*
 *   Convert ENUM_TYPE values to human readable format. USER_ROLE => User Role
 */
export const enumToHumanReadable = (enumValue) => {
  const noUnderScore = enumValue.replace('_', ' ');
  return textCapitalizeEach(noUnderScore);
};

/*
 *   Capitalize string
 */
export const textCapitalize = (text) => {
  if (!text) return null;
  const lowerCase = text.toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
};

/*
 *   Capitalize each word of string
 */
export const textCapitalizeEach = (text) => {
  if (!text) return null;
  const words = text.split(' ');
  return words.map((word) => textCapitalize(word)).join(' ');
};

// ** Creates span title
export const createSpanTitle = (title, length) => {
  if (!title) {
    return null;
  }
  return title.length > length ? title : null;
};

/*
 * Checks if string is null or empty
 */
export const isNullOrEmpty = (str) => !str || str == '';

// ** Get short text that might overflow
export const getShortText = (text, length) => {
  if (!text) {
    return null;
  }
  return text.length > length ? `${text.substring(0, length)}...` : text;
};

// ** Get short text that might overflow
export const getTextWithLength = (text, length) => (
  <span title={createSpanTitle(text, length)}>
    {getShortText(text, length)}
  </span>
);

export const convertStringToArray = (string, spiltBy) => {
  return string?.split(spiltBy);
};

// array of Extract numbers from string
export const extractNumberFromString = (str) => {
  return str?.match(/\d+/g)?.map((num) => parseInt(num));
};

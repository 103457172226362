export default {
  'assessmentPreview.next': 'Next Question',
  'assessmentPreview.prev': 'Previous Question',
  'assessmentPreview.question': 'Question #{number}',
  'assessmentPreview.groupedQuestion':
    'Answer questions {first} - {last} according to the question content below.',
  'assessmentPreview.groupSingle':
    'Question #{number} - Choose the <u>true</u> option.',
  'assessmentPreview.choose': 'Choose the <u>true</u> option.',
  'assessmentPreview.panel': 'Exam Panel',
  'assessmentPreview.flagged': 'Flagged',
  'assessmentPreview.unflagged': 'Unflagged',
  'assessmentPreview.attachments': 'Attachments',
  'assessmentPreview.attachmentWarn':
    'This question has {count} attachments containing video/audio files',
  'assessmentPreview.questionDetails': 'Question Details',
  'assessmentPreview.questionDetailsSub': 'Review and edit question details',
  'assessmentPreview.course': 'Course',
  'assessmentPreview.questionName': 'Question Name',
  'assessmentPreview.questionOwner': 'Question Owner',
  'assessmentPreview.questionApprovedDate': 'Approval Date',
  'assessmentPreview.questionAddedDate': 'Added Date',
  'assessmentPreview.questionLo': 'Learning Objective',
  'assessmentPreview.view': 'View',
  'assessmentPreview.edit': 'Edit',
  'assessmentPreview.examReport': 'Exam Report',
  'assessmentPreview.emptyQuestion':
    'This question was not answered by the candidate.',
  'assessmentPreview.acceptedCORRECT': 'Accepted as true for all',
  'assessmentPreview.acceptedREMOVE': 'Canceled',
  'assessmentPreview.topic': 'Topic: {topic}',
  'assessmentPreview.averageDuration': 'Average: {average}',
  'assessmentPreview.averagePoint': 'Overall Avg: {average}',
  'assessmentPreview.candidate': 'Candidate',
  'assessmentPreview.CORRECT-tooltip':
    'The question was considered true for all respondents. Therefore, the statistics of the question are not included in the performance screen and this assessment.',
  'assessmentPreview.REMOVE-tooltip':
    'The question is canceled for this assessment. Therefore, the statistics of the question are not included in the performance screen and this assessment.',
  'assessmentPreview.description': 'Assessment Description',
};

export const FONT_FAMILY = "'Lexend Deca', sans-serif";
export const FONT_WEIGHT_BOLD = 500;
export const FONT_WEIGHT = 300;
export const BADGE_ICON_SIZE = 11;
export const MAIN_FONT_COLOR = '#202124';
export const SUB_FONT_COLOR = '#4F4F54';
export const COLORS = {
  GREEN: { color: '#28c76f', bgColor: '#d4f4e2' },
  ORANGE: { color: '#fd7e14', bgColor: '#ffe5d0' },
  PURPLE: { color: '#6610f2', bgColor: '#e0cffc' },
  PINK: { color: '#fa616d', bgColor: '#fedfe2' },
  NAVY_BLUE: { color: '#0d6efd', bgColor: '#cfe2ff' },
  EMERALD: { color: '#024852', bgColor: '#ccdadc' },
};

export const COLOR_NAMES = {
  GREY: 'grey',
  GREEN: 'green',
  ORANGE: 'orange',
  PURPLE: 'purple',
  PINK: 'pink',
  NAVY_BLUE: 'navy-blue',
  EMERALD: 'emerald',
  YELLOW: 'yellow',
};

export default {
  'assessment.next': 'Sonraki Soru',
  'assessment.prev': 'Önceki Soru',
  'assessment.finish': 'Sınavı Bitir',
  'assessment.description': 'Değerlendirme Açıklaması',
  'assessment.examDesc': 'Değerlendirme Açıklaması',
  'assessment.finishConfirmationInfoEmpty':
    '{empty} adet boş sorunuz bulunmaktadır.',
  'assessment.finishConfirmationInfoFlagged':
    '{flagged} adet işaretli sorunuz bulunmaktadır.',
  'assessment.finishConfirmationInfoBoth':
    '{empty} adet boş ve {flagged} adet işaretli sorunuz bulunmaktadır.',
  'assessment.finishConfirmationMessage':
    'Sınavınızı sonlandırmak istiyor musunuz?',
  'assessment.finishConfirmationButton': 'Evet, sınavı bitir',
  'assessment.question': 'Soru #{number}',
  'assessment.groupedQuestion':
    '{first}. - {last}. soruları aşağıdaki soru içeriğine göre yanıtlayınız.',
  'assessment.groupSingle':
    'Soru #{number} - <u>Doğru</u> seçeneği işaretleyiniz.',
  'assessment.choose': '<u>Doğru</u> seçeneği işaretleyiniz.',
  'assessment.panel': 'Sınav Paneli',
  'assessment.flagged': 'İşareti kaldır',
  'assessment.unflagged': 'İşaretle',
  'assessment.session': 'Oturum Kimliği: ',
  'assessment.total': 'Kalan Süre: ',
  'assessment.section': 'Bölüm: ',
  'assessment.timeWarning': 'Sınavınızın tamamlanmasına sadece 5 dakika kaldı!',
  'assessment.time': '5 dakika',
  'assessment.warning': 'Uyarı!',
  'assessment.fullScreenWarning':
    'Sınav kuralları gereği;\nİnternet tarayıcınızı tam ekran yapmalıyız.\nTesti görüntülemek için ekranda herhangi bir yere tıklayın.',
  'assessment.focusWarning':
    'Şu an sınav kuralları dışında şüpheli bir davranış sergilediniz. Tüm bu davranışlar kayıt altına alınmaktadır.',
  'assessment.focusWarningButton': 'Evet, anladım.',
  'assessment.AUTHORIZED': 'Yetkili',
  'assessment.CANDIDATE': 'Aday',
  'assessment.screenshotWarning':
    'Sınav kuralları gereği;\nBu sınavda ekran yakalama yapılamaz.\nSınava devam etmek için ekran yakalama aracını kapatınız.',
  'assessment.dueToExamRules': 'Sınav kuralları gereği;',
  'assessment.pcForceWarning':
    'Bu sınava yalnızca bilgisayardan erişilebilir. Bu sınav için mobil cihaz kullanamazsınız.',
  'assessment.screenShareForceWarning': `Bu sınav için tüm ekran paylaşımı gereklidir.\nBu sınav için ekran paylaşımını kapatmayın!`,
  'assessment.attachments': 'Ekler',
  'assessment.attachmentWarn':
    'Bu soruda video/ses dosyası içeren {count} adet ek bulunmaktadır.',
  'assessment.cameraPermWarn1': 'Bu sınav için video kaydı gereklidir.',
  'assessment.cameraPermWarn2':
    'Sınava devam etmek için kameranızı tekrardan açın veya bağlayın.',
  'assessment.micPermWarn1': 'Bu sınav için ses kaydı gereklidir.',
  'assessment.micPermWarn2':
    'Sınava devam etmek için mikrofonunuzu tekrardan açın veya bağlayın.',
  'assessment.details': 'Detaylar',

  'assessment.help': 'Yardım',
  'assessment.understand': 'Anladım',
  'assessment.RECORD_MIC_title': 'Mikrofon bulunamadı',
  'assessment.RECORD_SCREEN_title': 'Ekran paylaşımı devre dışı bırakıldı',
  'assessment.RECORD_VIDEO_title': 'Kamera bulunamadı',
  'assessment.SCREEN_CAPTURE_title': 'Ekran yakalama tespit edildi',
  'assessment.FULL_SCREEN_title': 'Tam ekrandan çıkıldı',
  'assessment.FORCE_PC_title': 'Cihaz kullanılamıyor',
  'assessment.ONLY_ONE_SCREEN_title': 'Çoklu Ekran Tespit Edildi',
  'assessment.NO_NEW_TABS_title': 'Çoklu Sekme Tespit Edildi',
  'assessment.EXTENSION_DISABLED_title':
    'Examod Proctoring Extension Devre Dışı Bırakıldı',
  'assessment.PROCTORING_EXTENSION_title': 'Examod Proctoring Eklentisi',
  'assessment.RECORD_MIC_exp1': 'Sınav kuralları gereği, ses kaydı gereklidir.',
  'assessment.RECORD_MIC_exp2':
    'Sınava devam etmek için mikrofonunuzu tekrardan açın veya bağlayın.',
  'assessment.RECORD_VIDEO_exp1':
    'Sınav kuralları gereği, video kaydı gereklidir.',
  'assessment.RECORD_VIDEO_exp2':
    'Sınava devam etmek için kameranızı tekrardan açın veya bağlayın.',
  'assessment.RECORD_VIDEO_hint_exp3': 'Sayfayı yenileyin.',
  'assessment.RECORD_SCREEN_exp1':
    'Sınav kuralları gereği ekran paylaşımı gereklidir.',
  'assessment.RECORD_SCREEN_exp2':
    'Bu sınav için ekran paylaşımını kapatmayın!',
  'assessment.FORCE_PC_exp1':
    'Sınav kuralları gereği bu sınava sadece bilgisayardan girilebilir.',
  'assessment.FORCE_PC_exp2': 'Bu sınav için mobil cihaz kullanamazsınız.',
  'assessment.FULL_SCREEN_exp1':
    'Sınav kuralları gereği internet tarayıcınızı tam ekran yapmalıyız.',
  'assessment.FULL_SCREEN_exp2':
    'Sınavı görüntülemek için ekranda herhangi bir yere tıklayın.',
  'assessment.SCREEN_CAPTURE_exp1':
    'Sınav kuralları gereği ekran yakalama yapılamaz.',
  'assessment.SCREEN_CAPTURE_exp2':
    'Sınava devam etmek için ekran yakalama aracını kapatınız.',
  'assessment.RECORD_MIC_subtitle':
    'Mikrofonunuzu bulamadık. Lütfen aşağıdaki çözümleri deneyin.',
  'assessment.RECORD_MIC_hint_exp1':
    "Tarayıcınızın adres çubuğunda yer alan {icon} simgesine tıklayın ve Kamera'yı açın.",
  'assessment.RECORD_MIC_hint_exp2':
    'Sisteminizi kontrol edin. Mikrofonun kullanılabilir ve aktif olduğundan emin olun.',
  'assessment.RECORD_MIC_hint_exp3': 'Sayfayı yenileyin.',
  'assessment.RECORD_VIDEO_subtitle':
    'Kameranızı bulamadık. Lütfen aşağıdaki çözümleri deneyin.',
  'assessment.PROCTORING_EXTENSION_subtitle1':
    'Bu sınav <b>Examod Proctoring</b> eklentisi gerektirmektedir.{br}Lütfen Examod Proctoring eklentisini kontrol edin ve sayfayı yenileyin.',
  'assessment.PROCTORING_EXTENSION_subtitle2': 'Uzantı yüklü değilse',
  'assessment.PROCTORING_EXTENSION_button': "Chrome'a Ekle",
  'assessment.RECORD_VIDEO_hint_exp1':
    "Tarayıcınızın adres çubuğunda yer alan {icon} simgesine tıklayın ve Kamera'yı açın.",
  'assessment.RECORD_VIDEO_hint_exp2':
    'Sisteminizi kontrol edin. Kameranın kullanılabilir ve aktif olduğundan emin olun.',
  'assessment.PROCTORING_EXTENSION_hint_exp1':
    '<b>Examod Proctoring</b> eklentisinin yüklü ve güncel olduğundan emin olun.',
  'assessment.PROCTORING_EXTENSION_hint_exp2':
    '<b>Google Chrome</b> web tarayıcınızın adres çubuğundaki {icon} simgesine tıklayın ve <b>Examod Proctoring</b> eklentisini açın.',
  'assessment.PROCTORING_EXTENSION_hint_exp3': `<b>"Chrome'a Ekle"</b> düğmesine tıklayarak uzantıyı yükleyin.`,
  'assessment.PROCTORING_EXTENSION_hint_exp4':
    'Uzantı yüklemesi tamamlandıktan sonra sayfanızı <b>yenileyin</b>.',
  'assessment.ONLY_ONE_SCREEN_subtitle':
    'Çoklu ekran tespit ettik. Lütfen aşağıdaki çözümleri deneyin.',
  'assessment.ONLY_ONE_SCREEN_hint_exp1':
    'Cihazınıza yalnızca bir ekranın bağlı olduğundan emin olun.',
  'assessment.ONLY_ONE_SCREEN_hint_exp2':
    'Ekran yansıtma ve uzatma özelliklerini devre dışı bırakın.',
  'assessment.ONLY_ONE_SCREEN_hint_exp3': 'Sayfayı yenileyin.',
  'assessment.NO_NEW_TABS_subtitle':
    'Çoklu sekme tespit ettik. Lütfen aşağıdaki çözümleri deneyin.',
  'assessment.NO_NEW_TABS_hint_exp1':
    'Tarayıcınızda yalnızca sınav oturum sekmesinin açık olduğundan emin olun.',
  'assessment.NO_NEW_TABS_hint_exp2':
    'Sınav oturumu sekmesi hariç tüm sekmeleri kapatın.',
  'assessment.NO_NEW_TABS_hint_exp3': 'Sayfayı yenileyin.',
  'assessment.EXTENSION_DISABLED_exp1':
    'Sınav kuralları gereği ekran Examod Proctoring eklentisi aktif ve güncel olmalıdır.',
  'assessment.EXTENSION_DISABLED_exp2':
    'Examod Proctoring eklentisini kontrol edin ve ve sayfayı yenileyin.',
  'assessment.shareScreen': 'Ekranımı Paylaş',
  'assessment.resetToast':
    'Sınav oturumunuz yetkili tarafından sıfırlanmıştır.',
  'assessment.titleInformation':
    'Hay Aksi! Henüz <span>değerlendirme</span> bulunmamaktadır.',
  'assessment.messageInformation':
    'Henüz bir değerlendirmeniz bulunmamaktadır. Bir değerlendirmeye eklendiğinizde burada görüntülenecektir.',
};

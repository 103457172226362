import React from 'react';
import * as Icon from 'react-feather';
import { PATHS } from '../navigation/Paths';

const horizontalMenuConfig = [
  {
    type: 'groupHeader',
    groupTitle: 'menu.welcome',
  },
  {
    id: 'overview',
    title: 'menu.overview',
    type: 'item',
    icon: <Icon.Home size={20} />,
    navLink: PATHS.dashboard,
  },
  {
    type: 'groupHeader',
    groupTitle: 'menu.assessment',
  },
  {
    id: 'myExams',
    title: 'menu.exams',
    type: 'item',
    navLink: PATHS.myAssessments,
    icon: <Icon.Layers size={20} />,
  },
  {
    type: 'groupHeader',
    groupTitle: 'Help',
  },
  {
    id: 'newTicket',
    title: 'New Ticket',
    type: 'item',
    icon: <Icon.Send size={20} />,
    navLink: PATHS.newTicket,
  },
  {
    id: 'raiseSupport',
    title: 'Raise Support',
    icon: <Icon.LifeBuoy size={20} />,
    type: 'external-link',
    newTab: true,
    navLink: 'https://examod.com/',
  },
  {
    id: 'faq',
    title: 'FAQ',
    type: 'external-link',
    icon: <Icon.HelpCircle size={20} />,
    newTab: true,
    navLink: 'https://examod.com/resources/faq-candidate/',
  },
];

export default horizontalMenuConfig;

export default {
  "photoCheck.title": "Adayın Fotoğrafının Çekilmesi",
  "photoCheck.desc":
    "Aşağıdaki yönlendirmeleri takip ederek yüzünüzü önden, soldan ve sağdan üç fotoğrafını çekerek paylaşınız.",
  "photoCheck.approved": "Onaylandı",
  "photoCheck.notStarted": "Başlamadı",
  "photoCheck.frontView": "Önden Görünüm",
  "photoCheck.leftView": "Soldan Görünüm",
  "photoCheck.rightView": "Sağdan Görünüm",
  "photoCheck.takePhoto": "Fotoğraf Çek",
  "photoCheck.identity": "Kimlik Belgesi",
  "photoCheck.repeat": "Tekrarla",
  "photoCheck.space": "Fotoğraf çekimi için SPACE tuşunu kullanabilirsiniz.",

  "photoCheck.step1_1":
    "Kulaklarınız görünecek şekilde düz biçimde kameraya bakınız.",
  "photoCheck.step1_2": "Gözleriniz açık olmalıdır.",
  "photoCheck.step1_3": "Yüzler çene altına kadar görünür olmalıdır.",

  "photoCheck.step2_1":
    "Kafanızı kulağınızın tümü görünecek şekilde sola çeviriniz.",
  "photoCheck.step2_2": "Çeneniz tamamen görünür olmalıdır.",

  "photoCheck.step3_1":
    "Kafanızı kulağınızın tümü görünecek şekilde sağa çeviriniz.",
  "photoCheck.step3_2": "Çeneniz tamamen görünür olmalıdır.",

  "photoCheck.step4_1":
    "Resmi kurumlarca sağlanmış bir kimlik kartını ekrana yerleştiriniz.",
  "photoCheck.step4_2":
    "Kimlik kartı yakın tarihte çekilmiş bir fotoğrafınızı içermelidir.",
};

import React, { useContext } from 'react';

// ** Third party
import { Power, User } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

// ** Components
import UserAvatar from '../../../../components/examod/avatar/UserAvatar';

// ** Data
import { removeRememberToken } from '../../../../data/UserLocalDataSource';
import { logout } from '../../../../data/UserRepository';

// ** Navigator
import { navigateToLogin } from '../../../../Navigator';
import { history } from '../../../../history';

// ** Store
import { MainContext } from '../../../../store/Store';

const UserDropdown = (props) => {
  const { dispatch } = useContext(MainContext);

  const username = props.userInfo.username;
  const userNameSurname =
    props.userInfo.profile.name + ' ' + props.userInfo.profile.surname;

  const handleLogout = () => {
    if (logout()) {
      dispatch({
        user: null,
      });
      navigateToLogin();
      removeRememberToken();
    }
  };

  const handleNavigation = (e, path) => {
    e.preventDefault();
    history.push(path);
  };

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle tag="a" className="nav-link dropdown-user-link">
        <UserAvatar
          name={props.avatar[0]}
          size="md"
          color={props.avatar[1]}
          showStatus
        />
      </DropdownToggle>
      <DropdownMenu className="user-dropdown-profile-info-dropdown-menu">
        <DropdownItem
          className="user-dropdown-profile-info-item"
          tag="a"
          href="#"
        >
          <UserAvatar
            name={props.avatar[0]}
            size="md"
            color={props.avatar[1]}
            showStatus
          />
          <div className="user-dropdown-profile-info-item-user-info">
            <div className="user-dropdown-profile-info-item-user-name">
              {userNameSurname}
            </div>
            <div className="user-status sub-text">{username}</div>
          </div>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          tag="a"
          href="/profile"
          onClick={(e) => handleNavigation(e, '/profile')}
        >
          <User size={14} className="mr-50" />
          <span className="align-middle">
            <FormattedMessage id="profile.header" />
          </span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          tag="a"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleLogout();
          }}
        >
          <Power size={14} className="mr-50" />
          <span className="align-middle">
            {' '}
            <FormattedMessage id="profile.logout" />
          </span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserDropdown;

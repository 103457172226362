export default {
  'userDetails.verified': 'Verified',
  'userDetails.not_verified': 'Not Verified',
  'userDetails.profileButton': 'Profile',
  'userDetails.language': 'Preferred Language',
  'userDetails.systemId': 'System ID',
  'userDetails.email': 'Email',
  'userDetails.status': 'Status',
  'userStatus.active': 'Active',
  'userStatus.passivated': 'Passive',
  'userStatus.pending_invitation': 'Pending Invitation',
};

// ** Utility imports
import { useIntl } from 'react-intl';
import { isNullOrEmpty } from '../utility/collectionUtil';

// ** Supported User Types
export const userTypes = {
  AUTHORIZED: 'AUTHORIZED',
  CANDIDATE: 'CANDIDATE',
};

// ** Gets Full name of the User
export const getFullName = (user) => {
  if (user == null || user.profile == null) {
    return '';
  }

  return `${user.profile.name} ${user.profile.surname}`;
};

/*
 * Gets Role representation of the User
 */
export const getRoleRepresentation = (user) => {
  if (user == null || isNullOrEmpty(user.roles)) {
    return '';
  }

  return user.roles[0].role.name;
};

/*
 * Gets User Group representation of the User
 */
export const getUserGroupRepresentation = (user) => {
  if (user == null || isNullOrEmpty(user.userGroups)) {
    return '';
  }

  return user.userGroups[0].groupName;
};

/*
 * Gets the organization of the user
 */
export const getOrganization = (user) => {
  if (user == null) {
    return '';
  }

  return user.organization.name;
};

/*
 * Gets Avatar Letter
 */
export const getAvatarLetters = (user) => {
  if (user) {
    if (user.profile && user.profile.name && user.profile.surname) {
      let letters = (
        user.profile.name.substring(0, 1) + user.profile.surname.substring(0, 1)
      ).toUpperCase();
      return [letters, (letters.charCodeAt(0) + letters.charCodeAt(1)) % 6];
    } else {
      if (user.username) {
        let letter = user.username.substring(0, 1).toUpperCase();
        return [letter, letter.charCodeAt(0) % 6];
      }
    }
  }
  return ['E', 0];
};

/*
 * Checks if user is authorised
 */
export const isUserAuthorised = (user) => {
  return user.type === userTypes.AUTHORIZED;
};

/*
 * Checks if user is candidate
 */
export const isUserCandidate = (user) => {
  return user.type === userTypes.CANDIDATE;
};

export const GetUserStatusLocalisedText = (userStatus) => {
  let intl = useIntl();
  if (!userStatus) {
    return null;
  }
  let userStatusLocalisedText = intl.formatMessage({
    id: 'userStatus.' + userStatus.toLowerCase(),
  });

  return userStatusLocalisedText;
};

export const userStatuses = {
  ACTIVE: 'ACTIVE',
  PASSIVATED: 'PASSIVATED',
  PENDING_INVITATION: 'PENDING_INVITATION',
};

export default {
  'assignmentStatus.passive': 'Pasive',
  'assignmentStatus.ongoing': 'On going',
  'assignmentStatus.overdue': 'Expired',
  'assignmentStatus.completed': 'Finished',
  'assignmentStatus.not_attended': 'Not Attended',
  'assignmentStatus.not_started': 'Not Started',
  'assignmentStatus.unknown': 'Unknown',
  'assignmentStatus.invalidated': 'Invalidated',
  'assignmentStatus.cancelled': 'Canceled',
};
